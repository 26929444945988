import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Card, Space, Typography, Spin, message } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../../redux/hooks";
import { readRegulation } from "../../../../redux/actions/regulationsAction";
import { RootState } from "../../../../redux/store";
import Ellipse from "../../../../assets/Ellipse.png";
import { websiteBaseUrl } from "../../../../utils/apiHelper";

const { Text } = Typography;

interface Update {
  kind: string;
  slug: string;
  title: string;
  publishedAt: string;
}

interface LatestUpdatesProps {
  matchedCategoryId: string | null | undefined;
}

const SCROLL_CONTAINER_HEIGHT = "calc(55vh)";

const LatestUpdatesSection: React.FC<LatestUpdatesProps> = () => {
  const dispatch = useAppDispatch();
  const { regulationId } = useParams();
  const [regulationName, setRegulationName] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // Selectors
  const latestUpdates = useSelector<RootState, Update[]>(
    (state) => state.bulletinsData.bulletinsData || []
  );

  const sortedLatestUpdates = useMemo(
    () =>
      [...latestUpdates].sort(
        (a, b) =>
          new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime()
      ),
    [latestUpdates]
  );

  // Handlers
  const handleError = useCallback((error: Error, context: string) => {
    console.error(`Error in ${context}:`, error);
  }, []);

  const fetchData = useCallback(
    async (id: string) => {
      setLoading(true);
      try {
        const response = await dispatch(readRegulation(id));
        setRegulationName(response.name);
      } catch (error) {
        handleError(error as Error, "fetch regulation data");
      } finally {
        setLoading(false);
      }
    },
    [dispatch, handleError]
  );

  const handleOpenLink = useCallback((kind: string, slug: string) => {
    window.open(
      `${websiteBaseUrl}/${kind}/${slug}`,
      "_blank",
      "noopener noreferrer"
    );
  }, []);

  // Effects
  useEffect(() => {
    if (regulationId) {
      fetchData(regulationId);
    }
  }, [regulationId, fetchData]);

  // Render helpers
  const renderUpdateDate = (publishedAt: string) => {
    try {
      return format(new Date(publishedAt), "MMM dd");
    } catch (error) {
      return "Unknown Date";
    }
  };

  const renderUpdateCard = (update: Update, index: number) => (
    <div
      key={`${update.slug}-${index}`}
      className="update-card"
      onClick={() => handleOpenLink(update.kind, update.slug)}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          handleOpenLink(update.kind, update.slug);
        }
      }}
      role="button"
      tabIndex={0}
      aria-label={`View details for ${update.title}`}
      style={ {cursor:"pointer"}}
    >
      <Card
        className="update-item"
        style={{
          padding: "0px 16px",
          margin: "10px",
          background: "rgba(245, 245, 245, 1)",
          borderRadius: "8px",
          border: "1px solid #D9D9D9",
          marginBottom: "20px",
        }}
      >
        <Space direction="vertical" size="small" style={{ width: "100%" }}>
          <Text
            className="update-header"
            type="secondary"
            style={{
              fontSize: "18px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <span style={{ color: "#F7863E" }}>
              {regulationName || "Unknown Regulation"}
            </span>
            <img
              src={Ellipse}
              alt=""
              role="presentation"
              style={{ width: "10px", height: "10px" }}
            />
            <span style={{ color: "#8c8c8c" }}>
              {renderUpdateDate(update.publishedAt)}
            </span>
          </Text>
          <Space
            direction="horizontal"
            align="center"
            style={{ width: "100%" }}
          >
            <Text
              className="update-title"
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                lineHeight: "27px",
                textAlign: "left",
              }}
            >
              {update.title || "Untitled Update"}
            </Text>
            <ArrowRightOutlined
              className="arrow-icon"
              style={{
                color: "#F7863E",
                fontSize: "32px",
                marginLeft: "auto",
                transform: "rotate(-45deg)",
              }}
              aria-hidden="true"
            />
          </Space>
        </Space>
      </Card>
    </div>
  );

  return (
    <Card title="LATEST UPDATES" style={{ padding: 2 }}>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        {loading ? (
          <div style={{ textAlign: "center", padding: "20px" }}>
            <Spin tip="Loading latest updates..." />
          </div>
        ) : sortedLatestUpdates.length === 0 ? (
          <Text type="secondary">Loading latest updates...</Text>
        ) : (
          <div
            className="updates-container"
            style={{
              maxHeight: SCROLL_CONTAINER_HEIGHT,
              overflowY: "auto",
            }}
            role="list"
            aria-label="Latest updates"
          >
            {sortedLatestUpdates.map((update, index) =>
              renderUpdateCard(update, index)
            )}
          </div>
        )}
      </Space>
    </Card>
  );
};

export default LatestUpdatesSection;
