import React, { useState, useEffect } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import {
  Space,
  Typography,
  Button,
  Table,
  Input,
  notification,
  Tag,
} from "antd";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";
import { Modal } from "antd";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  bulkActionCampaignResponse,
  readCampaignResponses,
} from "../../../../redux/actions/CampaignManager/campaignManagerAction";
import AddComplianceApprove from "../../../../components/modals/addApproveComplianceModal";
import { getRegulationsFromLocalStorage } from "../../../../utils/localStore";
import ErrorBoundary from "../../../../utils/errorBoundary";
// import SearchableColumnHeader from "./SearchableColumnHeader";
import ExpandPendingResponse from "./ExpandPendingResponse";
import { SupplierResponse } from "../../../../utils/types/CampaignManager/campaignManager";

const { Search } = Input;
const { Text } = Typography;

export interface RejectionRequest {
  sendgridCampaignId: string;
  componentId: string;
  regulationNumber: string;
  type: "rejected";
  feedback: string | null;
}

const CampaignManagerPendingApproval: React.FC = () => {
  const dispatch = useAppDispatch();
  const [pendingResponse, setPendingResponse] = useState<SupplierResponse[]>(
    []
  );
  const [pendingResponseCount, setPendingResponseCount] = useState<number>(0);

  const [loading, setLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<
    SorterResult<SupplierResponse>
  >({});
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null>
  >({});
  const [search, setSearch] = useState<any>();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [feedbackText, setFeedbackText] = useState<string>("");
  const [selectedResponse, setSelectedResponse] = useState<any>({});
  const [fieldError, setFieldError] = useState("");
  const [api, contextHolder] = notification.useNotification();

  const handleOpenModal = (record: any) => {
    setSelectedResponse(record);
    setIsModalVisible(true);
    setFieldError("");
  };

  const handleReject = (comment?: boolean) => {
    if (comment) {
      if (!feedbackText.trim()) {
        setFieldError("Feedback is required!");
        return;
      }
    } else {
      setFieldError("");
    }
    dispatch(
      bulkActionCampaignResponse({
        filter: {
          manufacturer: selectedResponse.manufacturer,
          sendgridCampaignId: selectedResponse.sendgridCampaignId,
          status: "pending",
        },
        updates: {
          type: "rejected",
          ...(comment && { feedback: feedbackText || null }),
          componentId: selectedResponse.document?.componentId,
        },
        submitType: "bulk",
        responseType: selectedResponse.regulation,
      })
    ).then(() => {
      handleReload();
      setIsModalVisible(false);
      setFeedbackText("");
      setSelectedResponse({});
      setFieldError("");
    });
  };

  const columns: ColumnsType<SupplierResponse> = [
    {
      title: "Action",
      key: "operation",
      width: 100,
      render: (text: any, record: any) => (
        <Space style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <CloseCircleOutlined
            style={{ cursor: "pointer", fontSize: "16px", marginTop: "2px" }}
            onClick={() => handleOpenModal(record)}
            title="Reject"
          />
          <AddComplianceApprove
            type="icon"
            fetchData={handleReload}
            formData={record}
            isBulk={true}
            showNotification={showNotification}
          />
        </Space>
      ),
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
      sorter: true,
      width: 150,
      render: (text: string, record: any) => (
        <Space>
          <Tag bordered={false} color="processing">
            {record?.totalComponents}
          </Tag>
          <Text>{text}</Text>
        </Space>
      ),
    },
    {
      title: "Campaign Name",
      dataIndex: "campaign",
      key: "campaign",
      sorter: true,
      width: 150,
    },
    {
      title: "Regulation",
      dataIndex: "regulation",
      key: "regulation",
      sorter: true,
      width: 150,
      render: (value: string[], record: any) => {
        if (record.regulation !== "Material" || record.regulation !== "Scip") {
          const regulations = getRegulationsFromLocalStorage();
          const regulationData = value
            ? regulations?.find(
                (regulation: any) => regulation.regulationNumber === value
              )?.name || value
            : "";
          return <Text>{regulationData}</Text>;
        } else {
          <Text>{value}</Text>;
        }
      },
    },
    {
      title: "Submitted",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      render: (text: string, record: any) => (
        <Text>
          {text ? new Date(text).toLocaleString() : ""}
          <br />
          {record?.createdBy}
        </Text>
      ),
    },
  ].filter(Boolean);

  const onSearch = (value: any) => {
    setSearch({
      query: value?.key === "Enter" ? value?.target?.value : value,
    });
    setTablePagination({
      pageSize: tablePagination.pageSize,
      current: 1,
    });
  };

  const showNotification = (
    type: "info" | "error",
    message: string,
    description: string
  ) => {
    api[type]({
      message,
      description,
      duration: 5,
    });
  };

  const handleReload = () => {
    const pageSize = tablePagination.pageSize ?? 10;
    const currentPage = tablePagination.current ?? 1;
    const newTotal = pendingResponseCount - 1;
    const lastPage = Math.ceil(newTotal / pageSize);
    const newPage = currentPage > lastPage ? lastPage : currentPage;
    setTablePagination({
      current: newPage,
      pageSize: pageSize,
    });
    setTimeout(() => {
      fetchData();
    }, 2000);
  };

  const fetchData = () => {
    setLoading(true);
    dispatch(
      readCampaignResponses({
        limit: tablePagination.pageSize,
        page: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.order === "descend" ? false : true,
        softDelete: false,
        search: search?.query ? search?.query : "",
        statusType: "pending",
      })
    )
      .then((response: any) => {
        setPendingResponse(response?.supplierResponse || []);
        setPendingResponseCount(response?.count || 0);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleTableChange: TableProps<SupplierResponse>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<SupplierResponse> | SorterResult<SupplierResponse>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<SupplierResponse>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      return;
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
    search,
  ]);

  return (
    <div>
      <ErrorBoundary>
        {contextHolder}
        <Space
          align="end"
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "1rem",
          }}
        >
          <Space>
            <Search
              placeholder="Search using manufacturer name text"
              onSearch={onSearch}
              onPressEnter={onSearch}
              allowClear
              style={{ width: 350 }}
            />
          </Space>
        </Space>
        <Table
          loading={loading}
          dataSource={pendingResponse}
          scroll={{
            x: 1000,
            y: "calc(100vh - 285px)",
          }}
          columns={columns}
          pagination={{
            ...tablePagination,
            total: pendingResponseCount,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (totalCount) => `Total  ${totalCount}  items`,
          }}
          expandable={{
            expandedRowRender: (record) => (
              <ExpandPendingResponse formData={record} fetchData={fetchData} />
            ),
          }}
          rowKey={(record) =>
            `${record.manufacturer}+${record.sendgridCampaignId}+${
              record?.regulation || ""
            }`
          }
          onChange={handleTableChange}
        />
      </ErrorBoundary>
      <Modal
        title="Feedback for Rejection"
        open={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          setFeedbackText("");
        }}
        footer={[
          <>
            <Button
              onClick={() => handleReject(false)}
              style={{
                backgroundColor: "#00516E",
                color: "white",
                borderColor: "#00516E",
                marginRight: "8px",
              }}
            >
              Reject without Feedback
            </Button>
            <Button
              type="primary"
              onClick={() => handleReject(true)}
              style={{
                backgroundColor: "#F7863E",
                borderColor: "#F7863E",
              }}
            >
              Submit
            </Button>
          </>,
        ]}
        centered
        maskClosable={false}
      >
        <Input.TextArea
          rows={4}
          placeholder="Type Feedback"
          onChange={(e) => {
            setFeedbackText(e.target.value);
            setFieldError("");
          }}
          value={feedbackText}
          style={{
            borderColor: fieldError ? "red" : "#ACE8FF",
            marginBottom: "8px",
          }}
        />
        {fieldError && (
          <div style={{ color: "red", fontSize: 12, marginTop: -5 }}>
            {fieldError}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default CampaignManagerPendingApproval;
