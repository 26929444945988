import React, { useEffect, useMemo, useState, useCallback } from "react";
import {
  List,
  Row,
  Col,
  Space,
  Typography,
  Select,
  Input,
  Skeleton,
  Image,
  Pagination,
} from "antd";
import { PicLeftOutlined, BookOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import SkeletonImage from "antd/es/skeleton/Image";
import LatestUpdatesSection from "./LatestUpdates";
import { RootState, AppDispatch } from "../../../../redux/store";
import { listBlogs } from "../../../../redux/actions/blogsAction";
import { listBlogsCategoty } from "../../../../redux/actions/blogsCategoriesAction";
import { BlogsType } from "../../../../utils/types/blogs";
import Book from "../../../../assets/icons/book.svg";
import { websiteBaseUrl } from "../../../../utils/apiHelper";

const { Title, Text } = Typography;
const { Option } = Select;
const { Search } = Input;

interface RegulationsOverviewProps {
  categoryId?: string | null;
}

interface Category {
  title: string;
  id: string;
}

const ITEMS_PER_PAGE = 10;
const RegulationsOverview: React.FC<RegulationsOverviewProps> = ({
  categoryId,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [loadingData, setLoadingData] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedKind, setSelectedKind] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);

  // Selectors
  const blogData = useSelector((state: RootState) => state.blogs.blogs);
  const totalBlogs = useSelector((state: RootState) => state.blogs.blogCount); // Assuming your API response contains total count
  const blogsCategoryData = useSelector<RootState, Category[]>(
    (state) => state.blogsCategory.categories || []
  );

  // Memoized values
  const kindOptions = useMemo(() => {
    const kinds = Array.from(
      new Set(blogData.map((item) => item.kind || "unknown"))
    );
    return kinds.sort();
  }, [blogData]);

  const categoriesOptions = useMemo(() => {
    return blogsCategoryData
      .slice()
      .sort((a, b) => a.title.localeCompare(b.title))
      .map((category) => ({
        title: category.title,
        id: category.id,
      }));
  }, [blogsCategoryData]);

  const filteredBlogs = useMemo(() => {
    return blogData.filter((item) => {
      const matchesSearch = item.title
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      const matchesKind = selectedKind === "all" || item.kind === selectedKind;
      return matchesSearch && matchesKind;
    });
  }, [blogData, searchQuery, selectedKind]);

  // Handlers
  const handleError = useCallback((error: Error, context: string) => {
    console.error(`Error in ${context}:`, error);
  }, []);

  const handleKindChange = useCallback((value: string) => {
    setSelectedKind(value);
  }, []);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(e.target.value);
    },
    []
  );

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const openLinkInNewTab = useCallback((kind: string, slug: string) => {
    window.open(
      `${websiteBaseUrl}/${kind}/${slug}`,
      "_blank",
      "noopener noreferrer"
    );
  }, []);

  // Effects
  useEffect(() => {
    const fetchAllData = async () => {
      setLoadingData(true);
      try {
        await Promise.all([
          dispatch(listBlogs({})),
          dispatch(listBlogsCategoty({})),
        ]);
      } catch (error) {
        handleError(error as Error, "fetch data");
      } finally {
        setLoadingData(false);
      }
    };

    fetchAllData();
  }, [dispatch, handleError]);

  // Render helpers
  const renderBlogIcon = (kind: string) => {
    switch (kind) {
      case "blog":
        return (
          <PicLeftOutlined style={{ color: "#1890ff", fontSize: "24px" }} />
        );
      case "caseStudy":
        return <BookOutlined style={{ color: "#1890ff", fontSize: "24px" }} />;
      default:
        return <img src={Book} alt="" role="presentation" />;
    }
  };

  const renderDate = (publishedAt: string) => {
    try {
      return format(new Date(publishedAt), "EE MMM dd, yyyy");
    } catch {
      return "No Date Available";
    }
  };

  const renderLoadingSkeleton = () => (
    <>
      {Array.from({ length: ITEMS_PER_PAGE }).map((_, index) => (
        <div
          key={index}
          className="blog-item-skeleton"
          style={{
            marginBottom: "16px",
            padding: "16px",
            borderRadius: "8px",
            border: "1px solid #D9D9D9",
            backgroundColor: "#fff",
          }}
        >
          <Row gutter={16} align="middle">
            <Col span={8}>
              <SkeletonImage active />
            </Col>
            <Col span={16}>
              <Skeleton active paragraph={{ rows: 2, width: ["80%", "90%"] }} />
            </Col>
          </Row>
        </div>
      ))}
    </>
  );

  const renderBlogItem = (item: BlogsType) => (
    <List.Item
      key={item.title}
      className="blog-item"
      style={{
        marginBottom: "16px",
        padding: "16px",
        borderRadius: "8px",
        border: "1px solid #D9D9D9",
        backgroundColor: "#fff",
      }}
    >
      <Row gutter={16} align="middle">
        <Col span={8}>
          <Image
            width="100%"
            height="150px"
            alt={item.title}
            src={item.bannerImg?.formats?.small?.url}
            style={{ borderRadius: "8px", objectFit: "fill" }}
          />
        </Col>
        <Col span={16}>
          <Space direction="vertical" size="small">
            <Space size="small" align="center">
              {renderBlogIcon(item.kind)}
              <Text>{item.kind}</Text>
            </Space>
            <Title
              level={4}
              className="blog-title"
              style={{
                marginBottom: 0,
                color: "#00516E",
                fontSize: "18px",
                fontWeight: "700",
                cursor: "pointer",
              }}
              onClick={() => openLinkInNewTab(item.kind, item.slug)}
              role="link"
              tabIndex={0}
            >
              {item.title}
            </Title>
            <Text type="secondary" style={{ color: "#00516E" }}>
              {renderDate(item.publishedAt)} | {item.duration} min read
            </Text>
          </Space>
        </Col>
      </Row>
    </List.Item>
  );

  // Paginate the filtered blogs based on current page
  const paginatedBlogs = useMemo(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    return filteredBlogs.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  }, [filteredBlogs, currentPage]);

  return (
    <div className="regulations-overview">
      <Row
        className="filters-container"
        style={{ width: "100%", marginBottom: "1rem" }}
        justify="space-between"
        align="middle"
      >
        <Col>
          <Space size="middle">
            <Select
              defaultValue="all"
              style={{ width: 200 }}
              onChange={handleKindChange}
              aria-label="Filter by kind"
            >
              <Option value="all">All Kinds</Option>
              {kindOptions.map((kind) => (
                <Option key={kind} value={kind}>
                  {kind.charAt(0).toUpperCase() + kind.slice(1)}
                </Option>
              ))}
            </Select>
          </Space>
        </Col>

        <Col>
          <Space style={{ display: "flex", justifyContent: "flex-end" }}>
            <Search
              placeholder="Search Blog or Ebook"
              allowClear
              value={searchQuery}
              onChange={handleSearchChange}
              style={{ width: 350 }}
              aria-label="Search blogs and ebooks"
            />
          </Space>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={16}>
          <div
            className="blog-list-container"
            style={{
              height: "calc(100vh - 200px)",
              overflowY: "auto",
              paddingRight: "16px",
              position: "relative",
            }}
          >
            {loadingData ? (
              renderLoadingSkeleton()
            ) : (
              <>
                <List
                  itemLayout="vertical"
                  size="small"
                  dataSource={paginatedBlogs}
                  renderItem={renderBlogItem}
                />
              </>
            )}
          </div>
          <Col>
            <Pagination
              current={currentPage}
              pageSize={ITEMS_PER_PAGE}
              total={filteredBlogs.length}
              showTotal={(total) => `Total ${total} items`}
              align="end"
              onChange={handlePageChange}
              style={{ textAlign: "center", marginTop: "16px" }}
            />
          </Col>
        </Col>

        <Col span={8}>
          <LatestUpdatesSection matchedCategoryId={categoryId} />
        </Col>
      </Row>
    </div>
  );
};

export default RegulationsOverview;
