import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  BlogCategoriesState,
  BlogCategoriesType,
} from "../../utils/types/blogCategory";

const initialState: BlogCategoriesState = {
  categories: [],
  categoriesCount: 0,
  category: null,
};

export const blogsCategorySlice = createSlice({
  name: "blogsCategories",
  initialState,
  reducers: {
    // Action to set the list of blogs category
    setBlogsCategories: (
      state,
      action: PayloadAction<{
        blogCategory: BlogCategoriesType[];
        blogCategoryCount: number;
      }>
    ) => {
      state.categories = action.payload.blogCategory;
      state.categoriesCount = action.payload.blogCategoryCount;
    },
    // Action to set a single blog category
    readBlogsCategories: (state, action: PayloadAction<BlogCategoriesType>) => {
      state.category = action.payload;
    },
  },
});

export const { readBlogsCategories, setBlogsCategories } =
  blogsCategorySlice.actions;
export default blogsCategorySlice.reducer;
