import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  BulletinsDataType,
  BulletinsDataTypeState,
} from "../../utils/types/bulletins";

const initialState: BulletinsDataTypeState = {
  bulletinsData: [],
  bulletinsDataCount: 0,
  bulletin: null,
};

export const bulletinsDataSlice = createSlice({
  name: "bulletinsData",
  initialState,
  reducers: {
    // Action to set the list of bulletins
    setBulletinsData: (
      state,
      action: PayloadAction<{
        bulletinsData: BulletinsDataType[];
        bulletinsDataCount: number;
      }>
    ) => {
      state.bulletinsData = action.payload.bulletinsData;
      state.bulletinsDataCount = action.payload.bulletinsDataCount;
    },
    // Action to set a single bulletins
    readBulletinsData: (state, action: PayloadAction<BulletinsDataType>) => {
      state.bulletin = action.payload;
    },
  },
});

export const { setBulletinsData, readBulletinsData } = bulletinsDataSlice.actions;

export default bulletinsDataSlice.reducer;
