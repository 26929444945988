import React, { useState } from "react";
import { Button, Modal, Typography } from "antd";

const ValidationResult: React.FC<{ formData: any }> = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { Text } = Typography;

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        style={{
          color: props.formData?.SubmissionStatus?.includes("FAILED")
            ? "red"
            : "blue",
        }}
        type="text"
        onClick={showModal}
      >
        {props.formData?.SubmissionStatus}
      </Button>
      <Modal
        title="Submission Report"
        open={isModalOpen}
        centered
        closable={false}
        width={720}
        footer={<Button onClick={handleCancel}>Cancel</Button>}
      >
        <Text>
          <b>Status</b> : {props.formData?.SubmissionStatus}
        </Text>
        <br></br>
        <br></br>
        <Text>
          <b>Submission Type</b> : SCIP
        </Text>
        <br></br>
        <br></br>
        <Text>
          <b>Part Number</b> : {props.formData?.PrimaryArticleIdentifierValue}
        </Text>
        <br></br>
        <br></br>
        <Text>
          <b>SCIP Number</b> : {props.formData?.ScipUuid}
        </Text>
        <br></br>
        <br></br>
        <Text>
          <b>ECHA submission number</b> :{" "}
          {props.formData?.SubmissionReport?.submissionNumber}
        </Text>
        <br></br>
        <br></br>
        <Text>
          <b>ECHA report URL</b> : {props.formData?.SubmissionReport?.statusUrl}
        </Text>
        <br></br>
        <br></br>
        <Text>
          <b>ECHA API submission response </b> :{" "}
          {JSON.stringify(props.formData?.SubmissionReport)}
        </Text>
        <br></br>
        <br></br>
        <Text>
          <b>ECHA API submission report response </b> :{" "}
          {JSON.stringify(props.formData?.ValidationResult)}
        </Text>
        <br></br>
        <br></br>
        <Text>
          <b>ECHA legal entity </b> : {props.formData?.legalEntityUuid}
        </Text>
        <br></br>
        <br></br>
        <Text>
          <b>Created</b> :{" "}
          {new Date(props?.formData?.createdAt).toLocaleString(undefined, {
            hour: "2-digit",
            minute: "2-digit",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })}
        </Text>
        <br></br>
        <br></br>
        <Text>
          <b>Updated</b> :{" "}
          {new Date(props?.formData?.updatedAt).toLocaleString(undefined, {
            hour: "2-digit",
            minute: "2-digit",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })}
        </Text>
        <br></br>
      </Modal>
    </>
  );
};

export default ValidationResult;
