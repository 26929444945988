import React, { useEffect, useState } from "react";
import { Layout, ConfigProvider, Spin } from "antd";
import {
  Outlet,
  useParams,
  useOutletContext,
  useLocation,
  useNavigate,
} from "react-router-dom";

import SupplierSiderLayout from "../../components/layouts/supplierPortalSider";

import styles from "./suppliers.module.css";
import {
  setLocalStorage,
  storeRegulationsInLocalStorage,
} from "../../utils/localStore";
import "./../../index.css";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchCampaignData,
  fetchAnalytics,
} from "../../redux/actions/supplierPortalAction";
import SupplierExpireContactForm from "../../components/forms/ContactSupport";
import Welcome from "../../components/modals/Welcome";
import { scipPhrases } from "../../redux/actions/authAction";
import { phrasesExistence } from "../../utils/constants/indexedDb";

const { Content } = Layout;
type ContextType = { campaignAnalytics: any | null };
export function useCampaignAnalytics() {
  return useOutletContext<ContextType>();
}

const SupplierLayout: React.FC = () => {
  const { uniqueName, linkTenantNumber, linkCampaignId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { submitCompliance } = useAppSelector((state) => state.supplierPortal);

  const [theme, setTheme] = useState<any>({
    companyLogo: null,
    primaryColor: null,
    secondaryColor: null,
  });
  const [expiredAt, setExpiredAt] = useState<any>(null);
  const [campaignAnalytics, setCampaignAnalytics] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);

  const [isModalOpen, setIsModalOpen] = useState(true);
  const [welcome, setWelcome] = useState(null);

  const openModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    // Update the CSS variable for secondary color
    if (theme?.secondaryColor) {
      document.documentElement.style.setProperty(
        "--secondary-color",
        theme.secondaryColor
      );
      document.documentElement.style.setProperty(
        "--primary-color",
        theme.primaryColor
      );
    }
  }, [theme]);

  useEffect(() => {
    const payload = {
      name: uniqueName,
      tenantNumber: window.atob(linkTenantNumber!),
      campaignId: window.atob(linkCampaignId!),
    };
    setLocalStorage("user", payload);
    dispatch(fetchCampaignData(payload)).then((response) => {
      setWelcome(response.welcome);
      setLocalStorage("campaignData", response.campaignData);
      setLocalStorage("expireAt", response.expireAt);
      setTheme({
        companyLogo: response.companyLogo,
        primaryColor: response.primaryColor,
        secondaryColor: response.secondaryColor,
      });
      setExpiredAt(response.expireAt);
      storeRegulationsInLocalStorage(response.regulations);
      if (response.campaignData?.scip === "Yes") {
        phrasesExistence("PG6-60768").then((phraseExists: boolean) => {
          if (!phraseExists) {
            scipPhrases({});
          } else {
            return;
          }
        });
      }
    });
    dispatch(
      fetchAnalytics({
        name: uniqueName,
        tenantNumber: window.atob(linkTenantNumber!),
        campaignId: window.atob(linkCampaignId!),
      })
    ).then((response) => {
      setLocalStorage("campaignAnalytics", response);
      setCampaignAnalytics(response);
      setLoading(false);
      if (location.pathname.split("/").length === 5) {
        navigate("introduction");
      }
    });
  }, [submitCompliance, location.pathname]);

  return (
    <>
      {welcome && <Welcome isModalOpen={isModalOpen} openModal={openModal} />}
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: theme.secondaryColor
              ? theme.secondaryColor
              : "#F39237",
            borderRadius: 4,
            fontFamily: "Onest",
          },
          components: {
            Table: {
              rowSelectedBg: "#fff",
              rowSelectedHoverBg: "#fafafa",
            },
            Layout: {
              headerBg: "#ffffff",
              siderBg: theme.primaryColor ? theme.primaryColor : "#045B7C",
            },
            Menu: {
              darkItemBg: theme.primaryColor ? theme.primaryColor : "#045B7C",
              darkSubMenuItemBg: theme.primaryColor
                ? theme.primaryColor
                : "#045B7C",
            },
            Card: {
              headerBg: theme?.primaryColor || "#F39237",
              extraColor: "#fff",
            },
            Carousel: {
              dotWidth: 13,
              dotActiveWidth: 13,
              dotHeight: 13,
            },
          },
        }}
      >
        <Layout hasSider>
          <SupplierSiderLayout campaignAnalytics={campaignAnalytics} />
          <Layout>
            <Content className={styles.content}>
              <Spin spinning={loading} fullscreen />
              {expiredAt &&
                new Date().getTime() >= new Date(expiredAt).getTime() && (
                  <>
                    <SupplierExpireContactForm
                      tenantNumber={window.atob(linkTenantNumber!)}
                    />
                  </>
                )}
              <Outlet context={{ campaignAnalytics } satisfies ContextType} />
            </Content>
          </Layout>
        </Layout>
      </ConfigProvider>
    </>
  );
};

export default SupplierLayout;
