import React, { useEffect, useState } from "react";
import { PlusOutlined, MinusOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  Card,
  Row,
  Col,
  Divider,
  ColorPicker,
  Switch,
  DatePicker,
  Space,
} from "antd";

import ImageCropper from "../../../../components/layouts/ImageCropper";
import FileUploader from "../../../../components/layouts/FileUploader";

import { Subscription } from "../../../../utils/types/subscription";
import { Module } from "../../../../utils/types/module";
import { useAppDispatch } from "../../../../redux/hooks";
import { listModules } from "../../../../redux/actions/modulesAction";
import { listSubscriptions } from "../../../../redux/actions/subscriptionsAction";
import { updateTenant } from "../../../../redux/actions/tenantsAction";
import dayjs from "dayjs";

const TenantSubscription: React.FC<{
  id: string;
  tenantNumber: string;
  subscription?: Subscription;
  companyLogo?: string;
  primaryColor?: string;
  secondaryColor?: string;
  letterOfAuthorisation?: string;
  authorisedSender?: string;
  actAiConfigurations?: any;
  scipConfigurations: any;
  subscriptionStartDate: string;
  renewalPeriod: string;
  subscriptionEndDate: string;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [formSubscription] = Form.useForm();
  const [formWhitelabel] = Form.useForm();
  const [formActAi] = Form.useForm();
  const [formLetterOfAuthorisation] = Form.useForm();
  const [formScipConfiguration] = Form.useForm();
  const [formSubscriptionPeriod] = Form.useForm();
  const [moduleOptions, setModuleOptions] = useState<any[]>([]);
  const [subscriptionOptions, setSubscriptionOptions] = useState<any[]>([]);
  const [newSubscription, setNewSubscription] = useState<Subscription>();
  const [loadingSubscription, setLoadingSubscription] =
    useState<boolean>(false);
  const [loadingWhitelabel, setLoadingWhitelabel] = useState<boolean>(false);
  const [loadingActAi, setLoadingActAi] = useState<boolean>(false);
  const [loadingLetterOfAuthorisation, setLoadingLetterOfAuthorisation] =
    useState<boolean>(false);
  const [loadingScipConfiguration, setLoadingScipConfiguration] =
    useState<boolean>(false);

  const [loadingSubscriptionPeriod, setLoadingSubscriptionPeriod] =
    useState<boolean>(false);
  const [isUploading, setIsUploading] = useState(false);

  const disablePastDates = (current: dayjs.Dayjs) => {
    return current && current < dayjs().startOf("day");
  };

  const disableEndDate = (current: dayjs.Dayjs) => {
    const startDate = formSubscriptionPeriod.getFieldValue(
      "subscriptionStartDate"
    );
    return (
      current &&
      (startDate
        ? current < startDate.startOf("day") + 1
        : current < dayjs().startOf("day"))
    );
  };

  const fetchModules = (value: string) => {
    dispatch(
      listModules({
        pageSize: 0,
        pageNum: 1,
        sortBy: ["createdAt"],
        sortDesc: false,
        filters: JSON.stringify({
          type: ["client", "both"],
          number: { $regex: value, $options: "i" },
        }),
      })
    ).then((response) => {
      setModuleOptions(
        response.modules.map((module: Module) => ({
          label: module.name,
          value: module.name,
          default: module,
        }))
      );
    });
  };

  const fetchSubscriptions = (value: string) => {
    dispatch(
      listSubscriptions({
        pageSize: 10,
        pageNum: 1,
        sortBy: ["createdAt"],
        sortDesc: false,
        filters: JSON.stringify({
          number: { $regex: value, $options: "i" },
        }),
      })
    ).then((response) => {
      setSubscriptionOptions(
        response.subscriptions.map((subscription: Subscription) => ({
          label: subscription.name,
          value: subscription.name,
          default: subscription,
        }))
      );
    });
  };

  const fetchNewSubscription = (value: string) => {
    if (value && subscriptionOptions.find((e) => e.label === value)) {
      setNewSubscription(
        subscriptionOptions.find((e) => e.label === value).default
      );
      setTimeout(() => {
        formSubscription.resetFields();
      }, 1000);
    }
  };

  const onFormSubscriptionSubmit = (values: any) => {
    setLoadingSubscription(true);
    values.subscription.modules = values.subscription.modules.map((e: any) => {
      if (!e.number) {
        return {
          ...e,
          number: moduleOptions.find((m) => m.label === e.name).default.number,
        };
      } else return e;
    });
    dispatch(
      updateTenant(
        {
          currentLocationOrigin: window.location.origin,
          updates: {
            ...values,
          },
        },
        props.id
      )
    )
      .then(() => {
        formWhitelabel.resetFields();
        setLoadingSubscription(false);
      })
      .catch((e) => setLoadingSubscription(false));
  };

  const onFormWhitelabelSubmit = (values: any) => {
    setLoadingWhitelabel(true);
    dispatch(
      updateTenant(
        {
          updates: {
            ...values,
            primaryColor:
              typeof values.primaryColor !== "string"
                ? values.primaryColor.toHexString()
                : values.primaryColor,
            secondaryColor:
              typeof values.secondaryColor !== "string"
                ? values.secondaryColor.toHexString()
                : values.secondaryColor,
          },
        },
        props.id
      )
    )
      .then(() => {
        formWhitelabel.resetFields();
        setLoadingWhitelabel(false);
      })
      .catch((e) => setLoadingWhitelabel(false));
  };

  const onActAiFormSubmit = (values: any) => {
    const actvalues = {
      subscriptionEnable: values.subscriptionEnable,
      validityPeriod: values.validityPeriod,
      creditLimit: values.creditLimit,
      startDate: values.start
        ? dayjs(values.start)
        : props.actAiConfigurations.startDate,
    };

    setLoadingActAi(true);
    dispatch(
      updateTenant(
        {
          updates: {
            actAiConfigurations: actvalues,
            // actAiConfigurations:{subscriptionFeature:values.subscriptionFeature}
          },
        },
        props.id
      )
    )
      .then(() => {
        formActAi.resetFields();
        setLoadingActAi(false);
      })
      .catch((e) => setLoadingActAi(false));
  };

  const onFormScipConfigurationSubmit = (values: any) => {
    setLoadingScipConfiguration(true);
    dispatch(
      updateTenant(
        {
          updates: {
            ...values,
          },
        },
        props.id
      )
    )
      .then(() => {
        formScipConfiguration.resetFields();
        setLoadingScipConfiguration(false);
        window.location.reload();
      })
      .catch((e) => setLoadingScipConfiguration(false));
  };

  const onFormLetterOfAuthorisationSubmit = (values: any) => {
    setLoadingLetterOfAuthorisation(true);
    dispatch(
      updateTenant(
        {
          updates: {
            ...values,
          },
        },
        props.id
      )
    )
      .then(() => {
        setLoadingLetterOfAuthorisation(false);
      })
      .catch((e) => setLoadingLetterOfAuthorisation(false));
  };

  const onFormSubscriptionPeriodSubmit = (values: any) => {
    setLoadingSubscriptionPeriod(true);
    dispatch(
      updateTenant(
        {
          updates: {
            ...values,
          },
        },
        props.id
      )
    )
      .then(() => {
        formSubscriptionPeriod.resetFields();
        setLoadingSubscriptionPeriod(false);
        window.location.reload();
      })
      .catch((e) => setLoadingSubscriptionPeriod(false));
  };

  useEffect(() => {
    fetchSubscriptions("");
  }, []);

  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Card
          title="Subscription"
          extra={
            <Button
              type="primary"
              onClick={formSubscription.submit}
              loading={loadingSubscription}
              disabled={isUploading}
            >
              Save
            </Button>
          }
        >
          <Form
            form={formSubscription}
            layout="vertical"
            onFinish={onFormSubscriptionSubmit}
            initialValues={{
              subscription: newSubscription
                ? newSubscription
                : props.subscription,
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.List name="subscription">
                  {(fields) => (
                    <>
                      <Form.Item
                        name="name"
                        label="Change Subscription"
                        rules={[{ required: true, message: "Missing name" }]}
                      >
                        <Select
                          options={subscriptionOptions}
                          onSearch={(text) => fetchSubscriptions(text)}
                          onChange={(text) => fetchNewSubscription(text)}
                          placeholder="Please select a subscription"
                          showSearch
                          allowClear
                        />
                      </Form.Item>
                      <Divider orientation="left" plain>
                        Module Limits
                      </Divider>
                      <Form.List name="modules">
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map((field) => (
                              <Row
                                key={field.key}
                                gutter={[16, 16]}
                                justify="start"
                              >
                                <Col span={12}>
                                  <Form.Item
                                    name={[field.name, "name"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Missing name",
                                      },
                                    ]}
                                  >
                                    <Select
                                      options={moduleOptions}
                                      onSearch={(text) => fetchModules(text)}
                                      placeholder="Please select a module"
                                      showSearch
                                    />
                                  </Form.Item>
                                </Col>
                                <Col>
                                  <Form.Item
                                    name={[field.name, "count"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Missing count",
                                      },
                                    ]}
                                  >
                                    <InputNumber placeholder="Count" min={0} />
                                  </Form.Item>
                                </Col>
                                <Col>
                                  <Button
                                    type="text"
                                    danger
                                    icon={<MinusOutlined />}
                                    onClick={() => {
                                      remove(field.name);
                                    }}
                                  />
                                </Col>
                              </Row>
                            ))}
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                            >
                              Add Module
                            </Button>
                          </>
                        )}
                      </Form.List>
                    </>
                  )}
                </Form.List>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
      <Col span={12}>
        <Card
          title="Subscription Configurations"
          extra={
            <Button
              type="primary"
              onClick={formSubscriptionPeriod.submit}
              loading={loadingSubscriptionPeriod}
            >
              Save
            </Button>
          }
          style={{ marginBottom: "20px" }}
        >
          <Form
            form={formSubscriptionPeriod}
            layout="vertical"
            onFinish={onFormSubscriptionPeriodSubmit}
            initialValues={{
              subscriptionStartDate: dayjs(props.subscriptionStartDate),
              renewalPeriod: props.renewalPeriod,
              subscriptionEndDate: dayjs(props.subscriptionEndDate),
            }}
          >
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="subscriptionStartDate"
                  label="Subscription Start Date"
                  rules={[
                    {
                      required: true,
                      message: "Please select Subscription start date",
                    },
                  ]}
                >
                  <DatePicker
                    disabledDate={(current) => disablePastDates(dayjs(current))}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="renewalPeriod"
                  label="Renewal Period"
                  rules={[]}
                >
                  <Select placeholder="Renewal Period">
                    <Select.Option value="1 month">1 month</Select.Option>
                    <Select.Option value="3 months">3 months</Select.Option>
                    <Select.Option value="6 months">6 months</Select.Option>
                    <Select.Option value="12 months">12 months</Select.Option>
                    <Select.Option value="No renewal">No renewal</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="subscriptionEndDate"
                  label="Subscription End Date"
                  rules={[
                    {
                      required: true,
                      message: "Please select subscription end date",
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    disabledDate={(current) => disableEndDate(dayjs(current))}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        <Card
          title="Whitelabeling"
          style={{ height: "44%", marginBottom: "20px" }}
          extra={
            <Button
              type="primary"
              onClick={formWhitelabel.submit}
              loading={loadingWhitelabel}
              disabled={isUploading}
            >
              Save
            </Button>
          }
        >
          <Form
            form={formWhitelabel}
            layout="vertical"
            onFinish={onFormWhitelabelSubmit}
            initialValues={{
              companyLogo: props.companyLogo,
              primaryColor: props.primaryColor,
              secondaryColor: props.secondaryColor,
            }}
          >
            <Form.Item label="Brand Logo" name="companyLogo">
              <ImageCropper
                postUrl="admins/tenants/media/upload"
                tenantNumber={props.tenantNumber}
                setFormField={(value: string) =>
                  formWhitelabel.setFieldValue("companyLogo", value)
                }
                formField={props.companyLogo}
              />
            </Form.Item>
            <Form.Item name="primaryColor" label="Primary Color">
              <ColorPicker showText />
            </Form.Item>
            <Form.Item name="secondaryColor" label="Secondary Color">
              <ColorPicker showText />
            </Form.Item>
          </Form>
        </Card>
        <Card
          title="ACT AI Configuration"
          extra={
            <Button
              type="primary"
              onClick={formActAi.submit}
              loading={loadingActAi}
            >
              Save
            </Button>
          }
        >
          <Form
            form={formActAi}
            layout="vertical"
            initialValues={props.actAiConfigurations}
            onFinish={onActAiFormSubmit}
          >
            <Row gutter={24}>
              <Col span={24} sm={24} md={24}>
                <Form.Item
                  name="subscriptionEnable"
                  label="Subscription Enable"
                  rules={[
                    {
                      required: true,
                      message: "missing Subcription enable/disable",
                    },
                  ]}
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col span={8} sm={8} md={8}>
                <Form.Item
                  name="validityPeriod"
                  label="Validity Period"
                  rules={[
                    { required: true, message: "Missing Validity Period" },
                  ]}
                >
                  <Select placeholder="select validity period" allowClear>
                    <Select.Option value="monthly">Monthly</Select.Option>
                    {/* <Select.Option value="yearly">Yearly</Select.Option> */}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8} sm={8} md={8}>
                <Form.Item
                  name="start"
                  label="Start Date"
                  rules={[
                    { required: true, message: "Start Date is required" },
                  ]}
                >
                  <DatePicker
                    format="DD-MM-YYYY"
                    defaultValue={dayjs(props?.actAiConfigurations?.startDate)}
                    minDate={dayjs(dayjs(), "DD-MM-YYYY")}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={8} sm={8} md={8}>
                <Form.Item
                  name="creditLimit"
                  label="Number input for credit limit in US($)"
                  rules={[{ required: true, message: "Missing Credit Limit" }]}
                >
                  <InputNumber
                    placeholder="Enter credit limit"
                    min={0}
                    max={10} // You can set the minimum value here
                    style={{ width: "100%" }} // Optional: makes the input fill the width of the column
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
      <Col span={12}>
        <Card
          title="Campaign Configuration"
          style={{ height: "100%" }}
          extra={
            <Button
              type="primary"
              onClick={formLetterOfAuthorisation.submit}
              loading={loadingLetterOfAuthorisation}
              disabled={isUploading}
            >
              Save
            </Button>
          }
        >
          <Form
            form={formLetterOfAuthorisation}
            layout="vertical"
            onFinish={onFormLetterOfAuthorisationSubmit}
            initialValues={{
              letterOfAuthorisation: props.letterOfAuthorisation,
              authorisedSender: props.authorisedSender,
            }}
          >
            <Form.Item
              label="Authorised Sender"
              name="authorisedSender"
              rules={[
                {
                  type: "email",
                  message: "Please input a valid email address",
                },
              ]}
            >
              <Input placeholder="Email Address" />
            </Form.Item>
            <Form.Item
              label="Attach Letter of Authorisation"
              name="letterOfAuthorisation"
              rules={[]}
            >
              <FileUploader
                postUrl="admins/tenants/media/upload"
                tenantNumber={props.tenantNumber}
                setFormField={(value: string) =>
                  formLetterOfAuthorisation.setFieldValue(
                    "letterOfAuthorisation",
                    value
                  )
                }
                formField={props.letterOfAuthorisation}
                onUploadStatusChange={setIsUploading}
              />
            </Form.Item>
          </Form>
        </Card>
      </Col>
      <Col span={12}>
        <Card
          title="SCIP Configuration"
          style={{ height: "100%" }}
          extra={
            <Button
              type="primary"
              onClick={formScipConfiguration.submit}
              loading={loadingScipConfiguration}
            >
              Save
            </Button>
          }
        >
          <Form
            form={formScipConfiguration}
            layout="vertical"
            onFinish={onFormScipConfigurationSubmit}
            autoComplete="off"
            initialValues={{ scipConfigurations: props.scipConfigurations }}
          >
            <Row gutter={24} style={{ marginTop: "1rem" }}>
              <Col span={24} sm={24} md={24}>
                <Form.List name="scipConfigurations">
                  {(fields, { add, remove }, { errors }) => (
                    <Space
                      size="middle"
                      direction="vertical"
                      style={{ display: "flex" }}
                    >
                      {fields.map((field) => (
                        <>
                          <Card
                            type="inner"
                            size="small"
                            title={` ${field.name + 1}`}
                            key={field.key}
                            extra={
                              <DeleteOutlined
                                onClick={() => {
                                  remove(field.name);
                                }}
                              />
                            }
                            styles={{
                              header: {
                                color: "black",
                              },
                            }}
                          >
                            <Row gutter={[24, 24]}>
                              <Col span={12} sm={12} md={12}>
                                <Form.Item
                                  label="Legal Entity UUID"
                                  name={[field.name, "legalEntityUuid"]}
                                  rules={[
                                    {
                                      message:
                                        "Please input a legal entity UUID",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Legal Entity UUID" />
                                </Form.Item>
                              </Col>
                              <Col span={12} sm={12} md={12}>
                                <Form.Item
                                  label="Legal Entity Name"
                                  name={[field.name, "legalEntityName"]}
                                  rules={[
                                    {
                                      message:
                                        "Please input a legal entity name",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Legal Entity Name" />
                                </Form.Item>
                              </Col>
                              <Col span={12} sm={12} md={12}>
                                <Form.Item
                                  label="Username"
                                  name={[field.name, "userName"]}
                                  rules={[
                                    {
                                      message: "Please input a username",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Username" />
                                </Form.Item>
                              </Col>
                              <Col span={12} sm={12} md={12}>
                                <Form.Item
                                  label="S2S Key"
                                  name={[field.name, "apiKey"]}
                                  rules={[
                                    {
                                      message: "Please input a S2S key ",
                                    },
                                  ]}
                                >
                                  <Input placeholder="S2S key " />
                                </Form.Item>
                              </Col>
                              <Col span={12} sm={12} md={12}>
                                <Form.Item
                                  label="Entity Uuid"
                                  name={[field.name, "entityUuid"]}
                                >
                                  <Input disabled />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Card>
                        </>
                      ))}
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => {
                            add();
                          }}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Scip Configurations
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </Space>
                  )}
                </Form.List>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default TenantSubscription;
