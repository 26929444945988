import React, { useState } from "react";
import { Table, Space, Typography, Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  ExperimentOutlined,
  InboxOutlined,
  PlusOutlined,
  MinusOutlined,
} from "@ant-design/icons";

const { Text } = Typography;

interface MaterialType {
  name: string;
  weight: string;
}

interface SubstanceType {
  name: string;
  weight: string;
  casNumber: string;
}

const TableExpandMaterials: React.FC<{ materials?: MaterialType[] }> = (
  props
) => {
  const [expandedRowKey, setExpandedRowKey] = useState<string | null>(null);
  const [tooltipVisible, setTooltipVisible] = useState<string | null>(null);

  const materialColumns: ColumnsType<MaterialType> = [
    {
      title: "Material Name",
      dataIndex: "name",
      key: "name",
      render: (_, record: any) => (
        <Space style={{ marginRight: "2rem" }}>
          {record &&
          record?.substances.find(
            (ele: any) =>
              ele &&
              ele?.compliantalert &&
              ele?.compliantalert.toLowerCase() === "yes"
          ) ? (
            <InboxOutlined style={{ color: "red" }} />
          ) : (
            <InboxOutlined />
          )}
          <Text>{record && record.name}</Text>
        </Space>
      ),
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
      render: (text: string, record: any) => (
        <Text>
          {text} <small>{record.unitOfMeasure}</small>
        </Text>
      ),
    },
  ];

  const expandedRowRenderSubstances = (record: any) => {
    const columns: ColumnsType<SubstanceType> = [
      {
        title: "Substance Name",
        dataIndex: "name",
        key: "name",
        render: (_, record: any) => (
          <Space style={{ marginRight: "2rem" }}>
            {record && record && record?.compliantalert === "Yes" ? (
              <ExperimentOutlined style={{ color: "red" }} />
            ) : (
              <ExperimentOutlined />
            )}
            <Text>
              {record && record.name?.substance
                ? record.name.substance
                : record.name}
            </Text>
          </Space>
        ),
      },
      {
        title: "Weight",
        dataIndex: "weight",
        key: "weight",
        render: (text: string, record: any) => (
          <Text>
            {text} <small>{record.unitOfMeasure}</small>
          </Text>
        ),
      },
      {
        title: "Cas Number",
        dataIndex: "casNumber",
        key: "casNumber",
      },
    ];
    return (
      <Table
        columns={columns}
        dataSource={record?.substances}
        pagination={false}
        rowKey={(row) => row.name}
      />
    );
  };

  return (
    <div>
      <Table
        columns={materialColumns}
        dataSource={props?.materials}
        pagination={false}
        rowKey={(row) => row.name}
        scroll={{ x: 1000, y: "calc(100vh - 285px)" }}
        expandable={{
          expandedRowRender: expandedRowRenderSubstances,
          expandedRowKeys: expandedRowKey ? [expandedRowKey] : [],
          onExpand: (expanded, record) => {
            setExpandedRowKey(expanded ? record.name : null);
            setTooltipVisible(null);
          },
          expandIcon: ({ expanded, onExpand, record }) => (
            <Tooltip
              title={expanded ? "Collapse" : "Expand"}
              open={tooltipVisible === record.name}
              onOpenChange={(visible) =>
                setTooltipVisible(visible ? record.name : null)
              }
              mouseEnterDelay={0.3}
            >
              <span
                onClick={(e) => {
                  onExpand(record, e);
                  setTooltipVisible(null);
                }}
                style={{ cursor: "pointer" }}
              >
                {expanded ? <MinusOutlined /> : <PlusOutlined />}
              </span>
            </Tooltip>
          ),
        }}
      />
    </div>
  );
};
export default TableExpandMaterials;
