import React, { useEffect, useRef, useState } from "react";
import {
  Drawer,
  Form,
  Select,
  Collapse,
  TimePicker,
  Spin,
  Button,
  DatePicker,
  Tag,
  Upload,
} from "antd";
import { useAppDispatch } from "../../redux/hooks";
import {
  listComponentFilters,
  advanceFiltering,
} from "../../redux/actions/componentsAction";
import { materialsConfigurations } from "../../utils/constants/fmdAdvanceFilters";
import regulations from "../../utils/constants/regulations";
import { listProductFilters } from "../../redux/actions/productsAction";
import { listSuppliersFilters } from "../../redux/actions/suppliersAction";
import { UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
const { Panel } = Collapse;
const { RangePicker } = TimePicker;
const { Option } = Select;

interface AdvanceFiltersProps {
  visible: boolean;
  onClose: () => void;
  fetchFilterData: (
    columnName: string,
    columnSearch?: string,
    filterValue?: any
  ) => void;
  filterData: { [key: string]: any };
  onFiltersApply?: (filters: { [key: string]: any }) => void;
  parentType: "components" | "products" | "suppliers";
}
const AdvanceFilters: React.FC<AdvanceFiltersProps> = ({
  visible,
  onClose,
  onFiltersApply,
  parentType,
  fetchFilterData,
  filterData,
}) => {
  const [form] = Form.useForm();
  const [loadingData, setLoadingData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nestedData, setNestedData] = useState<any>(null);
  const [panelHeaders, setPanelHeaders] = useState<string[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<{
    [key: string]: any;
  }>({});
  const [selectedFilterTags, setSelectedFilterTags] = useState<{
    [key: string]: any[];
  }>({});
  const [selectedFieldValue, setSelectedFieldValue] = useState<any>(null);

  const dispatch = useAppDispatch();

  const fetchNestedData = async () => {
    setLoadingData(true);
    try {
      const response = await dispatch(
        advanceFiltering({ parentType: parentType })
      );
      setNestedData(response);
      const headers = extractPanelHeaders(response, parentType);
      setPanelHeaders(headers);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const extractPanelHeaders = (data: any, parentType: string) => {
    const headers: string[] = [];
    if (parentType === "components") {
      data
        .filter(
          (component: any) =>
            component.filter === true && component.regType !== "regulations"
        )
        .forEach((component: any) => {
          headers.push(component.text || `Field ${headers.length + 1}`);
        });
    }
    if (parentType === "products") {
      data.forEach((product: any) => {
        headers.push(product.text || `Product ${headers.length + 1}`);
      });
    }

    if (parentType === "suppliers") {
      data.forEach((supplier: any) => {
        headers.push(supplier.text || `Supplier ${headers.length + 1}`);
      });
    }

    if (parentType === "components") {
      data.forEach((regulation: any) => {
        if (regulation.regType === "regulations" && regulation.configuration) {
          regulation.configuration.forEach((config: any) => {
            if (config.text !== "Internal #" && config.text !== "Manufacturer")
              headers.push(
                `${regulation.name} - ${config.text}` ||
                  `Configuration ${headers.length + 1}`
              );
          });
        }
      });
    }

    return headers;
  };

  const handleFinish = () => {
    form
      .validateFields()
      .then((values) => {
        const transformedValues: Record<string, any[]> = {};

        Object.keys(values).forEach((key) => {
          if (key === "createdAt") {
            values[key][0] = `From:${values[key][0].format("MM-DD-YYYY")}`;
            values[key][1] = `To:${values[key][1].format("MM-DD-YYYY")}`;
          }
          const value = values[key];
          if (value !== undefined) {
            const valueAsArray = Array.isArray(value) ? value : [value];
            transformedValues[key] = valueAsArray;
          }
        });
        const mergedFilters = {
          ...transformedValues,
          ...selectedFilterTags,
        };
        setSelectedFilters(mergedFilters);
        onFiltersApply?.(mergedFilters);
        onClose();
      })
      .catch((error) => {
        console.error("Validation failed:", error);
      });
  };

  const handleReset = () => {
    form.resetFields();
    setSelectedFilters({});
    setSelectedFilterTags({});
  };

  const handleValuesChange = (changedValues: any, allValues: any) => {
    const updatedTags: { [key: string]: any[] } = {};
    Object.keys(allValues).forEach((key) => {
      const value = allValues[key];
      if (value !== undefined) {
        const valueAsArray = Array.isArray(value) ? value : [value];
        updatedTags[key] = valueAsArray;
      }
    });

    if (selectedFieldValue) {
      setSelectedFilterTags((prev: any) => {
        return { ...prev, ...updatedTags };
      });
    } else {
      setSelectedFilterTags(updatedTags);
    }
  };

  const handleTagClose = (key: string, value: any) => {
    const updatedTags = { ...selectedFilterTags };
    updatedTags[key] = updatedTags[key].filter(
      (tagValue: any) => tagValue !== value
    );
    if (updatedTags[key].length === 0) {
      delete updatedTags[key];
    }
    setSelectedFilterTags(updatedTags);
    form.setFieldsValue({
      [key]: updatedTags[key],
    });
  };

  useEffect(() => {
    if (visible) {
      fetchNestedData();
    }
  }, [visible]);

  useEffect(() => {
    if (selectedFieldValue) {
      renderNestedPanels(nestedData);
    }
  }, [selectedFieldValue]);

  const renderNestedPanels = (data: any) => {
    const gridConfigurations = data
      ?.filter(
        (component: any) =>
          component.type !== "specifications" &&
          component.type !== "productSpecifications" &&
          component.filter === true
      )
      .map((component: any, index: number) => (
        <Panel
          header={component.text || `Field ${index + 1}`}
          key={`component-${index}`}
        >
          {component.dataType === "Dropdown" && (
            <Form.Item name={component.value || `GridField_${index + 1}`}>
              <Select
                style={{ width: "100%", marginTop: 0 }}
                placeholder={`Select options for ${component.text}`}
                onFocus={() =>
                  fetchFilterData(component.value || `Field ${index + 1}`)
                }
                allowClear
              >
                {(component.dataValue || "")
                  .split(",")
                  .map((option: string, optionIndex: number) => (
                    <Option key={optionIndex} value={option}>
                      {option}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          )}
          {(component.dataType === "Textfield" ||
            component.dataType === "Multilist") && (
            <Form.Item name={component.value || `GridField_${index + 1}`}>
              <Select
                mode="multiple"
                style={{ width: "100%", marginTop: 0 }}
                placeholder={`Select options for ${component.text}`}
                onFocus={() =>
                  fetchFilterData(component.value || `Field ${index + 1}`)
                }
              >
                {(filterData[component.value] || []).map(
                  (
                    option: { text: string; value: string },
                    optionIndex: number
                  ) => (
                    <Option key={optionIndex} value={option.value}>
                      {option.text}
                    </Option>
                  )
                )}
              </Select>
            </Form.Item>
          )}
          {component.dataType === "Date" && (
            <Form.Item name={component.value || `GridField_${index + 1}`}>
              <DatePicker.RangePicker
                style={{ width: "100%", marginTop: 0 }}
                disabledDate={(current) => current && current.isAfter(dayjs())}
              />
            </Form.Item>
          )}
        </Panel>
      ));

    const regulations = data
      ?.filter((r: any) => r.regType === "regulations")
      .filter((regulation: any) => regulation.name);
    if (parentType === "components" && regulations.length > 0) {
      gridConfigurations.push(
        <Panel header={<strong>REGULATIONS</strong>} key="regulations">
          <Collapse expandIconPosition="right">
            {regulations.map((regulation: any, index: number) => (
              <Panel header={regulation.name} key={`regulation-${index}`}>
                {regulation.configuration &&
                Array.isArray(regulation.configuration) ? (
                  <Collapse>
                    {regulation.configuration
                      .filter(
                        (config: any) =>
                          config.text !== "Internal #" &&
                          config.text !== "Manufacturer"
                      )
                      .map((config: any, configIndex: number) => (
                        <Panel
                          header={
                            config.text || `Configuration ${configIndex + 1}`
                          }
                          key={`configuration-${index}-${configIndex}`}
                        >
                          {config.dataType === "Dropdown" && (
                            <Form.Item
                              name={`${regulation.regulationNumber}.${
                                config.value || `RegConfig_${configIndex}`
                              }`}
                            >
                              <Select
                                style={{ width: "100%", marginTop: 0 }}
                                placeholder={`Select ${config.text}`}
                                allowClear
                              >
                                {(config.dataValue || "")
                                  .split(",")
                                  .map(
                                    (option: string, optionIndex: number) => (
                                      <Option key={optionIndex} value={option}>
                                        {option}
                                      </Option>
                                    )
                                  )}
                              </Select>
                            </Form.Item>
                          )}
                          {(config.dataType === "Textfield" ||
                            config.dataType === "Multilist") && (
                            <Form.Item
                              name={config.value || `RegConfig_${configIndex}`}
                            >
                              <Select
                                mode="multiple"
                                style={{ width: "100%", marginTop: 0 }}
                                placeholder={`Select options for ${config.text}`}
                                onFocus={() =>
                                  fetchFilterData(
                                    config.value || `Field ${configIndex + 1}`
                                  )
                                }
                              >
                                {(filterData[config.value] || []).map(
                                  (
                                    option: {
                                      text: string;
                                      value: string;
                                    },
                                    optionIndex: number
                                  ) => (
                                    <Option
                                      key={optionIndex}
                                      value={option.value}
                                    >
                                      {option.text}
                                    </Option>
                                  )
                                )}
                              </Select>
                            </Form.Item>
                          )}
                          {config.dataType === "Fileinput" && (
                            <Form.Item
                              name={`${regulation.regulationNumber}.${
                                config.value || `RegConfig_${configIndex}`
                              }`}
                            >
                              <Select
                                style={{ width: "100%", marginTop: 0 }}
                                placeholder={`Select a file for ${config.text}`}
                                onFocus={() =>
                                  fetchFilterData(
                                    config.value || `complianceDocument`,
                                    "",
                                    {}
                                  )
                                }
                                allowClear
                              >
                                <Option value="present">PRESENT</Option>
                                <Option value="not Present">NOT PRESENT</Option>
                              </Select>
                            </Form.Item>
                          )}
                        </Panel>
                      ))}
                  </Collapse>
                ) : (
                  <div>No configurations available</div>
                )}
              </Panel>
            ))}
          </Collapse>
        </Panel>
      );
    }

    const specifications = data
      ?.filter((s: any) => s.type === "specifications")
      .filter((specification: any) => specification.text);
    if (parentType === "components" && specifications.length > 0) {
      const uniqueSpecifications = new Set();
      gridConfigurations.push(
        <Panel header={<strong>SPECIFICATIONS</strong>} key="specifications">
          <Collapse expandIconPosition="right">
            {specifications
              .filter((specification: any) => {
                if (
                  specification.filter &&
                  !uniqueSpecifications.has(specification.value)
                ) {
                  uniqueSpecifications.add(specification.value);
                  return true;
                }
                return false;
              })
              .map((specification: any, index: number) => (
                <Panel
                  header={specification.text || `Specification ${index + 1}`}
                  key={`specification${index}`}
                >
                  {["Textfield", "Dropdown", "DateInput"].includes(
                    specification.dataType
                  ) ? (
                    <Form.Item
                      name={`specification.${
                        specification.value || `SpecField_${index}`
                      }`}
                    >
                      {specification.dataType === "DateInput" ? (
                        <DatePicker
                          style={{ width: "100%" }}
                          placeholder={`Select options for ${
                            specification.text || `Date`
                          }`}
                          onChange={(date, dateString) => {
                            console.log(`Selected date: ${dateString}`);
                          }}
                        />
                      ) : (
                        <Select
                          mode={
                            specification.dataType === "Textfield"
                              ? "multiple"
                              : undefined
                          }
                          style={{ width: "100%", marginTop: 0 }}
                          placeholder={`Select options for ${
                            specification.text || `Specification ${index + 1}`
                          }`}
                          onFocus={() =>
                            fetchFilterData(
                              `specification.${specification.value}` ||
                                `Specification ${index + 1}`
                            )
                          }
                          allowClear
                        >
                          {(specification.dataType === "Dropdown"
                            ? (specification.dataValue || "").split(",")
                            : filterData[
                                `specification.${specification.value}`
                              ] || []
                          ).map((option: any, optionIndex: number) => (
                            <Option
                              key={optionIndex}
                              value={option.value || option}
                              allowClear
                            >
                              {option.text || option}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  ) : (
                    <div>
                      <strong>No valid options available</strong>
                    </div>
                  )}
                </Panel>
              ))}
          </Collapse>
        </Panel>
      );
    }
    const productSpecifications = data
      ?.filter((p: any) => p.type === "productSpecifications")
      .filter((specification: any) => specification.text);
    if (parentType === "products" && productSpecifications.length > 0) {
      const uniqueSpecifications = new Set();
      gridConfigurations.push(
        <Panel
          header={<strong>PRODUCT SPECIFICATIONS</strong>}
          key="productSpecifications"
        >
          <Collapse expandIconPosition="right">
            {productSpecifications
              .filter((specification: any) => {
                if (
                  specification.filter &&
                  !uniqueSpecifications.has(specification.value)
                ) {
                  uniqueSpecifications.add(specification.value);
                  return true;
                }
                return false;
              })
              .map((specification: any, index: number) => (
                <Panel
                  header={specification.text || `specification ${index + 1}`}
                  key={`specification${index}`}
                >
                  {["Textfield", "Dropdown", "DateInput"].includes(
                    specification.dataType
                  ) ? (
                    <Form.Item
                      name={`specification.${
                        specification.value || `SpecField_${index}`
                      }`}
                    >
                      {specification.dataType === "DateInput" ? (
                        <DatePicker
                          style={{ width: "100%" }}
                          placeholder={`Select options for ${
                            specification.text || `Date`
                          }`}
                          onChange={(date, dateString) => {
                            console.log(`Selected date: ${dateString}`);
                          }}
                        />
                      ) : (
                        <Select
                          mode={
                            specification.dataType === "Textfield"
                              ? "multiple"
                              : undefined
                          }
                          style={{ width: "100%", marginTop: 0 }}
                          placeholder={`Select options for ${
                            specification.text || `Specification ${index + 1}`
                          }`}
                          onFocus={() =>
                            fetchFilterData(
                              `specification.${specification.value}` ||
                                `Specification ${index + 1}`
                            )
                          }
                          allowClear
                        >
                          {(specification.dataType === "Dropdown"
                            ? (specification.dataValue || "").split(",")
                            : filterData[
                                `specification.${specification.value}`
                              ] || []
                          ).map((option: any, optionIndex: number) => (
                            <Option
                              key={optionIndex}
                              value={option.value || option}
                              allowClear
                            >
                              {option.text || option}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  ) : (
                    <div>
                      <strong>No valid options available</strong>
                    </div>
                  )}
                </Panel>
              ))}
          </Collapse>
        </Panel>
      );
    }
    const materialFields = materialsConfigurations.filter(
      (m) => m.type === "materials" && m.filter
    );
    const materialDisclosure = data?.filter(
      (m: any) => m.type === "materialDisclosure"
    );

    const hasMaterialDisclosure =
      materialDisclosure && materialDisclosure.length > 0;
    const filteredMaterialFields = hasMaterialDisclosure ? materialFields : [];

    if (parentType === "components" && filteredMaterialFields.length > 0) {
      gridConfigurations.push(
        <Panel
          header={<strong>MATERIAL DISCLOSURE</strong>}
          key="materialDisclosure"
        >
          <Collapse>
            {filteredMaterialFields.map((field, index) => (
              <Panel header={<>{field.text}</>} key={field.value}>
                <Form.Item name={field.value}>
                  {field.dataType === "Dropdown" ? (
                    <Select
                      style={{ width: "100%", marginTop: 0 }}
                      placeholder={`Select a file for ${field.text}`}
                      onFocus={() =>
                        fetchFilterData(field.value || `fmdDocument`, "", {})
                      }
                      allowClear
                    >
                      <Option value="present">PRESENT</Option>
                      <Option value="not Present">NOT PRESENT</Option>
                    </Select>
                  ) : (
                    <Select
                      mode="multiple"
                      style={{ width: "100%", marginTop: 0 }}
                      placeholder={`Select options for ${field.text}`}
                      onFocus={() => fetchFilterData(field.value)}
                      allowClear
                    >
                      {(filterData[field.value] || []).map(
                        (option: any, index: number) => (
                          <Option key={index} value={option.value}>
                            {option.text}
                          </Option>
                        )
                      )}
                    </Select>
                  )}
                </Form.Item>
              </Panel>
            ))}
          </Collapse>
        </Panel>
      );
    }
    return gridConfigurations;
  };

  const selectedField = (value: string) => {
    setSelectedFieldValue(value);

    const regulation = value.split("-");
    const lastElementFromArray = regulation[regulation.length - 1].trim();
    const regNum = regulation.shift()?.trim();

    setNestedData((prevData: any[]) => {
      const filteredByRegulations = prevData
        .filter((e: any) => e.regType === "regulations" && e.name === regNum)
        .map((i: any) => ({
          ...i,
          configuration: i.configuration.filter(
            (o: any) => o.text === lastElementFromArray
          ),
        }));

      const filteredByText = prevData.filter(
        (item: any) => item.text === value
      );
      return [...filteredByRegulations, ...filteredByText];
    });
  };
  return (
    <Drawer
      title="Advanced Filters"
      width={480}
      onClose={onClose}
      open={visible}
      footer={
        <div style={{ textAlign: "right" }}>
          <Button
            onClick={() => {
              form.resetFields();
              setSelectedFilterTags({});
              setSelectedFieldValue(null);
              fetchNestedData();
            }}
            style={{ marginRight: 8 }}
          >
            Reset
          </Button>
          <Button
            type="primary"
            onClick={handleFinish}
            disabled={
              Object.keys(selectedFilterTags).length === 0 ||
              Object.values(selectedFilterTags).every(
                (tags) => tags.length === 0
              )
            }
          >
            Apply
          </Button>
        </div>
      }
    >
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginBottom: "1rem",
          flexWrap: "wrap",
        }}
      >
        {Object.keys(selectedFilterTags).length > 0 && (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "6px",
            }}
          >
            {Object.keys(selectedFilterTags).map((key) =>
              selectedFilterTags[key].map((value, idx) => (
                <Tag
                  className="filter-tag"
                  key={`${key}-${idx}`}
                  bordered={false}
                  closable
                  onClose={() => handleTagClose(key, value)}
                  style={{
                    display: "inline-block", // Ensures tags are inline
                    padding: "4px 8px", // Add padding for better spacing
                  }}
                >
                  {/* {key === "whereUsed" ? `${text}` : `${value}:`} */}
                  {`${value}`}
                </Tag>
              ))
            )}
          </div>
        )}
      </div>

      <Form form={form} onValuesChange={handleValuesChange}>
        <Select
          style={{ width: "100%", marginBottom: 16, marginTop: 0 }}
          placeholder={"Select option for Advance filtering fields"}
          showSearch
          onSelect={(value) => selectedField(value)}
          allowClear
          value={selectedFieldValue}
          onClear={() => {
            setSelectedFieldValue(null);
            fetchNestedData();
          }}
        >
          {panelHeaders.map((header, index) => (
            <Option
              key={index}
              value={header}
              disabled={
                selectedFieldValue !== null && selectedFieldValue !== header
              }
            >
              {header}
            </Option>
          ))}
        </Select>
        {loading ? (
          <Spin
            tip="Loading data..."
            style={{ display: "block", marginTop: 100, textAlign: "center" }}
          />
        ) : nestedData ? (
          <Collapse>{renderNestedPanels(nestedData)}</Collapse>
        ) : (
          <Spin
            tip="Loading data..."
            style={{ display: "block", marginTop: 100, textAlign: "center" }}
          />
        )}
      </Form>
    </Drawer>
  );
};

export default AdvanceFilters;
