import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CampaignsState,
  Campaign,
} from "../../../utils/types/CampaignManager/campaignManager";

const initialState: CampaignsState = {
  campaigns: [],
  campaignsCount: 0,
  campaign: {} as Campaign,
  inbox: [],
  inboxCount: 0,
  pendingResponse: [],
  pendingResponseCount: 0,
  campaignResponse: [],
};

export const campaignsSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    setCampaigns: (
      state,
      action: PayloadAction<{ campaigns: Campaign[]; count: number }>
    ) => {
      state.campaigns = action.payload.campaigns;
      state.campaignsCount = action.payload.count;
    },
    setCampaignData: (state, action: PayloadAction<any>) => {
      state.campaign = action.payload.campaign;
    },
    setInbox: (state, action: PayloadAction<any>) => {
      state.inbox = action.payload.inbox;
      state.inboxCount = action.payload.count;
    },
    setCampaignResponse: (state, action: PayloadAction<any>) => {
      state.campaignResponse = action.payload.campaignResponse;
    },
  },
});

export const { setCampaigns, setCampaignData, setInbox, setCampaignResponse } =
  campaignsSlice.actions;

export default campaignsSlice.reducer;
