import React, { useEffect, useState } from "react";
import {
  Table,
  Space,
  Tag,
  Typography,
  Modal,
  Input,
  Button,
  message,
  Tooltip,
  Popconfirm,
} from "antd";
import { ExportOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { SupplierResponse } from "../../../../utils/types/CampaignManager/campaignManager";
import ReadSupportingDocument from "../../../../components/modals/ReadSupportingDocuments";
import {
  updateCampaignResponse,
  readCampaignSupplierResponse,
} from "../../../../redux/actions/CampaignManager/campaignManagerAction";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  ColumnsType,
  SorterResult,
  TablePaginationConfig,
} from "antd/es/table/interface";
import { getRegulationsFromLocalStorage } from "../../../../utils/localStore";
import { Regulation } from "../../../../utils/types/regulation";
import { ReactComponent as RevertIcon } from "../../../../assets/icons/reply icon.svg";

const { Text } = Typography;

const ExpandRejectedResponse: React.FC<{
  formData: any;
  fetchData: any;
}> = (props) => {
  const dispatch = useAppDispatch();
  const regulations = getRegulationsFromLocalStorage() ?? [];

  const [subscriptionEnable, setSubscriptionEnable] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [supplierResponse, setSupplierResponse] = useState<any[]>([]);

  const fetchData = () => {
    setLoading(true);
    dispatch(
      readCampaignSupplierResponse({
        sendgridCampaignId: props.formData?.sendgridCampaignId,
        manufacturer: props.formData?.manufacturer,
        regulation: props.formData?.regulation,
        limit: tablePagination.pageSize,
        page: tablePagination.current,
        sortBy: ["createdAt"],
        sortDesc: tableSorter.column
          ? tableSorter.order === "descend"
            ? false
            : true
          : false,
        statusType: "rejected",
      })
    )
      .then((response: any) => {
        setSupplierResponse(response?.supplierResponse);
        setSubscriptionEnable(response.subscriptionEnable);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );

  const [tableSorter, setTableSorter] = useState<
    SorterResult<SupplierResponse>
  >({});

  const onClickViewLink = (filename: string) => {
    window.open(filename, "_blank");
  };

  const handleRevert = (record: any) => {
    let bulkApprovePayload;
    if (
      props.formData.regulation !== "Material" &&
      props.formData.regulation !== "Scip"
    ) {
      bulkApprovePayload = {
        supplierComplianceResponse: record?.supplierComplianceResponse,
        type: "pending",
      };
    } else {
      bulkApprovePayload = {
        type: "pending",
      };
    }
    dispatch(
      updateCampaignResponse({
        filter: {
          sendgridCampaignId: record.sendgridCampaignId,
          componentId: record.componentId,
        },
        updates: bulkApprovePayload,
        responseType: props.formData.regulation,
      })
    ).then(() => {
      fetchData();
      props.fetchData();
    });
  };

  const columns: ColumnsType<SupplierResponse> = [
    {
      title: "Action",
      key: "operation",
      width: 150,
      render: (text: any, record: any) => (
        <Space style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Popconfirm
            title="Revert back to Pending Approval"
            description={`Are you sure want to revert ${record.manufacturer?.itemNumber} and ${props.formData?.regulation} back to Pending Approval?`}
            onConfirm={() => handleRevert(record)}
            okText="Yes"
            cancelText="Cancel"
            overlayStyle={{ width: 300 }}
          >
            <Button
              type="text"
              icon={<RevertIcon style={{ width: 20, height: 20 }} />}
              title="Revert back to Pending Approval"
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const regulationHeaders = regulations
    ?.filter(
      (regulation: Regulation) =>
        regulation.regulationNumber === props.formData.regulation
    )
    .flatMap((config: any) =>
      config.configuration
        .filter(
          (o: any) =>
            !o.hidden &&
            o.value !== "manufacturer" &&
            o.value !== "internalItemNumber" &&
            o.value !== "operations"
        )
        .map((k: any) => ({
          title: `${k.text}`,
          dataIndex: `${k.value}`,
          key: `${
            k.value.includes("complianceOutputs")
              ? props.formData.regulation + "." + k.value
              : k.value
          }`,
          render: (value: any, record: any) => {
            if (k.value === "complianceDocument") {
              return record.supplierComplianceResponse?.complianceDocument ? (
                record.supplierComplianceResponse?.complianceDocument?.includes(
                  "http"
                ) ? (
                  <span>
                    <ExportOutlined
                      onClick={() =>
                        onClickViewLink(
                          record.supplierComplianceResponse?.complianceDocument
                        )
                      }
                    />{" "}
                    Link
                  </span>
                ) : (
                  <ReadSupportingDocument
                    postUrl="campaignmanager/document/download"
                    filename={
                      record.supplierComplianceResponse?.complianceDocument
                    }
                    foldername="campaign"
                    campaignId={record.sendgridCampaignId}
                  />
                )
              ) : null;
            } else if (k.value.includes("complianceOutputs.compliant")) {
              let compliant = null;
              if (
                subscriptionEnable &&
                record.supplierComplianceResponse.supplierPdfReader !== null
              ) {
                compliant =
                  record &&
                  record.supplierComplianceResponse?.complianceOutputs
                    ?.compliant &&
                  record.supplierComplianceResponse?.supplierPdfReader?.answers?.complianceOutputsCompliant?.toUpperCase() ===
                    record.supplierComplianceResponse?.complianceOutputs?.compliant?.toUpperCase() ? (
                    ""
                  ) : (
                    <Tooltip title="Act AI: Status not validated. Review document for compliance confirmation.">
                      <ExclamationCircleFilled
                        style={{ color: "red", marginLeft: "3px" }}
                      />
                    </Tooltip>
                  );
              }

              if (
                record &&
                record.supplierComplianceResponse?.complianceOutputs &&
                record.supplierComplianceResponse?.complianceOutputs?.compliant?.toUpperCase() ===
                  "YES"
              ) {
                return (
                  <>
                    <Tag color="green"> YES </Tag>
                    {compliant}
                  </>
                );
              } else if (
                record &&
                record.supplierComplianceResponse?.complianceOutputs &&
                record.supplierComplianceResponse?.complianceOutputs?.compliant?.toUpperCase() ===
                  "NO"
              ) {
                return (
                  <>
                    <Tag color="red"> NO </Tag>
                    {compliant}
                  </>
                );
              } else if (
                record &&
                record.supplierComplianceResponse?.complianceOutputs &&
                record.supplierComplianceResponse?.complianceOutputs?.compliant?.toUpperCase() ===
                  "YES WITH EXEMPTION"
              ) {
                return (
                  <>
                    <Tag color="orange"> YES WITH EXEMPTION </Tag>
                    {compliant}
                  </>
                );
              } else if (
                record &&
                record.supplierComplianceResponse?.complianceOutputs &&
                record.supplierComplianceResponse?.complianceOutputs?.compliant?.toUpperCase() ===
                  "UNKNOWN"
              ) {
                return (
                  <>
                    <Tag color="default"> UNKNOWN </Tag>
                    {compliant}
                  </>
                );
              } else {
                return (
                  <>
                    <Tag color="grey">
                      {record &&
                      record.supplierComplianceResponse?.complianceOutputs &&
                      record.supplierComplianceResponse?.complianceOutputs
                        ?.compliant
                        ? record.supplierComplianceResponse?.complianceOutputs
                            ?.compliant
                        : ""}
                    </Tag>
                    {compliant}
                  </>
                );
              }
            } else if (k.value.includes("complianceOutputs.version")) {
              if (
                subscriptionEnable &&
                record.supplierComplianceResponse.supplierPdfReader !== null
              ) {
                return (
                  <Text>
                    {record && record.supplierComplianceResponse
                      ? record.supplierComplianceResponse?.complianceOutputs
                          ?.version
                      : ""}
                    {record &&
                    record.supplierComplianceResponse?.complianceOutputs
                      ?.version &&
                    record.supplierComplianceResponse?.supplierPdfReader?.answers?.complianceOutputsVersion
                      ?.replace(/\s/g, "")
                      .includes(
                        record.supplierComplianceResponse?.complianceOutputs?.version?.replace(
                          /\s/g,
                          ""
                        )
                      ) ? (
                      ""
                    ) : (
                      <Tooltip title="Act AI: Regulation version missing/mismatched or document date unknown. Verify.">
                        <ExclamationCircleFilled
                          style={{ color: "red", marginLeft: "3px" }}
                        />{" "}
                      </Tooltip>
                    )}
                  </Text>
                );
              } else {
                return (
                  <Text>
                    {record && record.supplierComplianceResponse
                      ? record.supplierComplianceResponse?.complianceOutputs
                          ?.version
                      : ""}
                  </Text>
                );
              }
            } else if (k.value.includes("complianceOutputs.exemption")) {
              if (
                subscriptionEnable &&
                record.supplierComplianceResponse.supplierPdfReader !== null
              ) {
                return (
                  <Text
                    style={{ width: 200 }}
                    ellipsis={{
                      tooltip:
                        record.supplierComplianceResponse?.complianceOutputs?.exemption?.join(
                          ", "
                        ),
                    }}
                  >
                    {record && record.supplierComplianceResponse
                      ? record.supplierComplianceResponse?.complianceOutputs?.exemption?.join(
                          ", "
                        )
                      : ""}
                    {record &&
                      record.supplierComplianceResponse?.complianceOutputs
                        ?.exemption?.length > 0 &&
                      (typeof record.supplierComplianceResponse
                        ?.supplierPdfReader?.answers
                        ?.complianceOutputsExemption === "string" &&
                      record.supplierComplianceResponse?.supplierPdfReader?.answers?.complianceOutputsExemption
                        ?.replace(/\s/g, "")
                        .includes(
                          record.supplierComplianceResponse?.complianceOutputs?.exemption
                            ?.join("")
                            .replace(/\s/g, "")
                        ) ? (
                        ""
                      ) : (
                        <Tooltip title="Act AI: Exemption(s) not found. Check document for specified exemptions.">
                          <ExclamationCircleFilled
                            style={{ color: "red", marginLeft: "3px" }}
                          />{" "}
                        </Tooltip>
                      ))}
                  </Text>
                );
              } else {
                return (
                  <Text
                    style={{ width: 200 }}
                    ellipsis={{
                      tooltip:
                        record.supplierComplianceResponse?.complianceOutputs?.exemption?.join(
                          ", "
                        ),
                    }}
                  >
                    {record && record.supplierComplianceResponse
                      ? record.supplierComplianceResponse?.complianceOutputs?.exemption?.join(
                          ", "
                        )
                      : ""}
                  </Text>
                );
              }
            } else if (k.value.includes("complianceOutputs.substance")) {
              if (
                subscriptionEnable &&
                record.supplierComplianceResponse.supplierPdfReader !== null
              ) {
                return (
                  <Text
                    style={{ width: 200 }}
                    ellipsis={{
                      tooltip:
                        record.supplierComplianceResponse?.complianceOutputs
                          ?.substance,
                    }}
                  >
                    {record && record.supplierComplianceResponse
                      ? record.supplierComplianceResponse?.complianceOutputs
                          ?.substance
                      : ""}
                    {record &&
                      record.supplierComplianceResponse?.complianceOutputs
                        ?.substance?.length > 0 &&
                      (record.supplierComplianceResponse?.supplierPdfReader?.answers?.complianceOutputsSubstances
                        ?.replace(/\s/g, "")
                        .includes(
                          record.supplierComplianceResponse?.complianceOutputs?.substance?.replace(
                            /\s/g,
                            ""
                          )
                        ) ? (
                        ""
                      ) : (
                        <Tooltip title="Act AI:Substance of Concern not identified. Review document for details.">
                          <ExclamationCircleFilled
                            style={{ color: "red", marginLeft: "3px" }}
                          />{" "}
                        </Tooltip>
                      ))}
                  </Text>
                );
              } else {
                return (
                  <Text
                    style={{ width: 200 }}
                    ellipsis={{
                      tooltip:
                        record.supplierComplianceResponse?.complianceOutputs
                          ?.substance,
                    }}
                  >
                    {record && record.supplierComplianceResponse
                      ? record.supplierComplianceResponse?.complianceOutputs
                          ?.substance
                      : ""}
                  </Text>
                );
              }
            } else {
              return (
                <Text>
                  {record.supplierComplianceResponse &&
                  record.supplierComplianceResponse?.complianceOutputs
                    ? record.supplierComplianceResponse?.complianceOutputs[
                        k.value?.split(".").pop().toString()
                      ]
                    : ""}
                </Text>
              );
            }
          },
        }))
    );

  const regulationColumns = [
    {
      title: "Manufacturer Part Number",
      dataIndex: ["manufacturer", "itemNumber"],
      key: "manufacturerPartNumber",
      sorter: true,
      width: 200,
      hidden: false,
      render: (manufacturer: any, record: any) => {
        if (
          subscriptionEnable &&
          record.supplierComplianceResponse.supplierPdfReader !== null
        ) {
          const itemNumberMismatch =
            manufacturer.itemNumber &&
            !record.supplierComplianceResponse?.supplierPdfReader?.answers?.manufacturerItemNumber?.includes(
              manufacturer.itemNumber
            );

          let tooltipTitle = "";

          if (itemNumberMismatch) {
            tooltipTitle =
              "Act AI:Exact match not found. Check for series/part family/division-level declarations.";
          }

          return (
            <Text>
              {manufacturer}
              <br />
              {itemNumberMismatch && (
                <Tooltip title={tooltipTitle}>
                  <ExclamationCircleFilled
                    style={{ color: "red", marginLeft: "5px" }}
                  />
                </Tooltip>
              )}
            </Text>
          );
        } else {
          return (
            <Text>
              {manufacturer}
              <br />
            </Text>
          );
        }
      },
    },
    {
      title: "Regulation",
      dataIndex: "supplierComplianceResponse.regulationNumber",
      key: "supplierComplianceResponse.regulationNumber",
      sorter: true,
      width: 150,
      hidden: false,
      render: (text: any, record: any) => {
        if (
          subscriptionEnable &&
          record.supplierComplianceResponse.supplierPdfReader !== null
        ) {
          return (
            <Text>
              {record && record.supplierComplianceResponse
                ? record.supplierComplianceResponse?.regulationNumber
                    ?.replaceAll("_", " ")
                    .toUpperCase()
                : ""}
              {(record &&
                record.supplierComplianceResponse?.regulationNumber &&
                record.supplierComplianceResponse?.supplierPdfReader?.answers?.regulationNames
                  ?.toUpperCase()
                  .includes(
                    record.supplierComplianceResponse?.regulationNumber.toUpperCase()
                  )) ||
              record.supplierComplianceResponse?.supplierPdfReader?.answers?.regulationNames
                ?.toUpperCase()
                .includes(
                  record.supplierComplianceResponse?.regulationNumber
                    ?.replaceAll("_", " ")
                    .toUpperCase()
                ) ||
              record.supplierComplianceResponse?.supplierPdfReader?.answers?.regulationNames
                ?.toUpperCase()
                .includes(
                  record.supplierComplianceResponse?.regulationNumber
                    .toUpperCase()
                    ?.replaceAll(/_|EU/g, " ")
                ) ? (
                ""
              ) : (
                <Tooltip title="Act AI:Regulation reference missing. Please validate.">
                  <ExclamationCircleFilled
                    style={{ color: "red", marginLeft: "3px" }}
                  />
                </Tooltip>
              )}
            </Text>
          );
        } else {
          return (
            <Text>
              {regulations?.find(
                (regulation: any) =>
                  regulation.regulationNumber ===
                  record.supplierComplianceResponse?.regulationNumber
              )?.name || ""}
            </Text>
          );
        }
      },
    },
    {
      title: "Feeback",
      dataIndex: ["feedback", "text"],
      key: "feedback.text",
      width: 200,
      render: (text: string) => (
        <Text style={{ width: 200 }} ellipsis={{ tooltip: text }}>
          {text}
        </Text>
      ),
    },
    ...regulationHeaders,
    {
      title: "Rejected",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      render: (_: string, record: any) => (
        <Text>
          {record.supplierComplianceResponse
            ? new Date(
                record.supplierComplianceResponse?.updatedAt
              ).toLocaleString()
            : ""}
          <br />
          {record.supplierComplianceResponse?.updatedBy}
        </Text>
      ),
    },
  ];

  const materialColumns = [
    {
      title: "Manufacturer Part Number",
      dataIndex: ["manufacturer", "itemNumber"],
      key: "manufacturer.itemNumber",
      //sorter: true,
      width: 200,
      render: (manufacturer: any) => <Text>{manufacturer}</Text>,
    },
    {
      title: "Feeback",
      dataIndex: ["feedback", "text"],
      key: "feedback.text",
      //sorter: true,
      width: 200,
      render: (text: string) => (
        <Text style={{ width: 200 }} ellipsis={{ tooltip: text }}>
          {text}
        </Text>
      ),
    },
    {
      title: "FMD Document",
      dataIndex: "supplierMaterialsResponse.fmdDocument",
      key: "supplierMaterialsResponse.fmdDocument",
      width: 150,
      render: (text: string, record: any) => {
        return record.supplierMaterialsResponse?.fmdDocument ? (
          <ReadSupportingDocument
            postUrl="public/suppliers/document/download"
            filename={record.supplierMaterialsResponse?.fmdDocument}
            foldername="campaign"
            subfolder="materials"
            campaignId={record.sendgridCampaignId}
          />
        ) : null;
      },
    },
    {
      title: "Weight",
      dataIndex: "partWeight",
      key: "partWeight",
      width: 150,
      render: (_: string, record: any) => (
        <Text>
          {record.supplierMaterialsResponse?.partWeight}{" "}
          <small>{record.supplierMaterialsResponse?.partWeightUnit}</small>
        </Text>
      ),
    },
    {
      title: "Rejected",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      render: (_: string, record: any) => (
        <Text>
          {record.supplierMaterialsResponse
            ? new Date(
                record.supplierMaterialsResponse?.updatedAt
              ).toLocaleString()
            : ""}
          <br />
          {record.supplierMaterialsResponse?.updatedBy}
        </Text>
      ),
    },
  ];

  const scipColumns = [
    {
      title: "Manufacturer Part Number",
      dataIndex: ["manufacturer", "itemNumber"],
      key: "manufacturer.itemNumber",
      //sorter: true,
      width: 200,
      render: (manufacturer: any) => <Text>{manufacturer}</Text>,
    },
    {
      title: "Feeback",
      dataIndex: ["feedback", "text"],
      key: "feedback.text",
      //sorter: true,
      width: 200,
      render: (text: string) => (
        <Text style={{ width: 200 }} ellipsis={{ tooltip: text }}>
          {text}
        </Text>
      ),
    },
    {
      title: "Article ID Type",
      dataIndex: "supplierScipResponse.PrimaryArticleIdentifierType",
      key: "supplierScipResponse.PrimaryArticleIdentifierType",
      //sorter: true,
      width: 150,
      render: (_: string, record: any) => (
        <Text>
          {record.supplierScipResponse
            ? record?.supplierScipResponse?.PrimaryArticleIdentifierType?.text
            : ""}
        </Text>
      ),
    },
    {
      title: "Article ID Value",
      dataIndex: "supplierScipResponse.PrimaryArticleIdentifierValue",
      key: "supplierScipResponse.PrimaryArticleIdentifierValue",
      //sorter: true,
      width: 150,
      render: (_: string, record: any) => (
        <Text>
          {record.supplierScipResponse?.PrimaryArticleIdentifierValue}
        </Text>
      ),
    },
    {
      title: "Rejected",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 150,
      render: (text: string, record: any) => (
        <Text>
          {record.supplierScipResponse
            ? new Date(record.supplierScipResponse?.updatedAt).toLocaleString()
            : ""}
          <br />
          {record.supplierScipResponse?.updatedBy}
        </Text>
      ),
    },
  ];

  if (props.formData.regulation === "Material") {
    columns.push(...materialColumns);
  } else if (props.formData.regulation === "Scip") {
    columns.push(...scipColumns);
  } else {
    columns.push(...regulationColumns);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={supplierResponse}
        pagination={false}
        rowKey="componentId"
        style={{
          background: "#E6F7FF",
          marginLeft: -16,
          marginRight: -16,
          width: "calc(100% + 32px)",
        }}
        scroll={{ y: "calc(70vh - 280px)" }}
        components={{
          header: {
            cell: (props: React.HTMLAttributes<HTMLTableHeaderCellElement>) => (
              <th
                {...props}
                style={{
                  backgroundColor: "#045B7C",
                  color: "white",
                  fontWeight: "bold",
                  padding: "12px 16px",
                }}
              />
            ),
          },
        }}
      />
    </>
  );
};

export default ExpandRejectedResponse;
