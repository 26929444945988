import {
  privatePostApi,
  privateGetApi,
  privatePutApi,
  privateDeleteApi,
} from "../../../utils/apiHelper";
import {
  Campaign,
  SupplierResponse,
} from "../../../utils/types/CampaignManager/campaignManager";
import {
  setCampaigns,
  setCampaignData,
  setInbox,
  setCampaignResponse,
} from "../../slices/CampaignManager/campaignManagersSlice";
import { Dispatch } from "redux";

export const bulkActionCampaignResponse = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/campaignmanager/supplier/bulk-action", payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Bulk Operations
 */
export const listCampaigns = (payload: any) => (dispatch: Dispatch) => {
  return new Promise<void>((resolve, reject) => {
    privatePostApi("/campaignmanager/read", payload)
      .then((response: any) => {
        if (response.status === 200) {
          dispatch(setCampaigns(response.data.data));
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

/**
 * CRUD Operations
 */
export const createCampaignManager = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/campaignmanager", payload)
      .then((response: any) => {
        if (response && response.status === 201 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const readCampaignManager = (payload: any) => (dispatch: Dispatch) => {
  return new Promise<Campaign>((resolve, reject) => {
    privatePostApi("/campaignmanager/read/single", payload)
      .then((response: any) => {
        if (response.status === 200) {
          dispatch(setCampaignData(response.data.data));
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const readCampaignInbox = (payload: any) => (dispatch: Dispatch) => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/campaignmanager/inbox/read", payload)
      .then((response: any) => {
        if (response.status === 200) {
          dispatch(setInbox(response.data.data));
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

// Send Mail
export const sendMail = (payload: any) => (dispatch: Dispatch) => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/campaignmanager/inbox/sendmail", payload)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

// Update Mail
export const updateCampaignInbox = (payload: any) => (dispatch: Dispatch) => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/campaignmanager/inbox/update", payload)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

// Draft Mail
export const draftCampaignInbox = (payload: any) => (dispatch: Dispatch) => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/campaignmanager/inbox/draft", payload)
      .then((response: any) => {
        if (response.status === 200) {
          // dispatch(setInbox(response.data.data));
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

// Delete Mail
export const deleteCampaignInbox = (payload: any) => (dispatch: Dispatch) => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/campaignmanager/inbox/delete", payload)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const readCampaignResponses = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/campaignmanager/response/read", payload)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const readCampaignSupplierResponse = (payload: any) => () => {
  return new Promise<SupplierResponse>((resolve, reject) => {
    privatePostApi("/campaignmanager/response/supplier", payload)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const readCampaignSupplierAnalytics = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/campaignmanager/supplier/analytics", payload)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const readCampaignData = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/campaignmanager/data/read", payload)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const readCampaignComplianceResponse = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/campaignmanager/supplier/read", payload)
      .then((response: any) => {
        if (response.status === 200) {
          setCampaignResponse(response.data.data);
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const updateCampaignResponse = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/campaignmanager/supplier/update", payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateCampaigns = (payload: any, id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePutApi("/campaignmanager/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.result);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteCampaigns = (id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privateDeleteApi("/campaignmanager/" + id)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const uploadCampaignDocument = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/campaignmanager/document/upload", payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
