import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BlogsType, BlogState } from "../../utils/types/blogs";

const initialState: BlogState = {
  blogs: [],
  blogCount: 0,
  blog: null,
};

export const blogSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {
    // Action to set the list of blogs
    setBlogs: (
      state,
      action: PayloadAction<{
        blogs: BlogsType[];
        blogCount: number;
      }>
    ) => {
      state.blogs = action.payload.blogs;
      state.blogCount = action.payload.blogCount;
    },
    // Action to set a single blog
    readBlog: (state, action: PayloadAction<BlogsType>) => {
      state.blog = action.payload;
    },
  },
});

export const { setBlogs, readBlog } = blogSlice.actions;
export default blogSlice.reducer;
