import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Space,
  Spin,
  Typography,
  Form,
  Input,
  Alert,
  Table,
} from "antd";
import {
  readDownloadExport,
  readDownloadStatus,
  readDownloadResult,
  updateScip,
  echaScipSubmision,
  echaEventsCheck,
  generateEchaToken,
} from "../../../redux/actions/scipAction";
import { useAppDispatch } from "../../../redux/hooks";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { getLocalStorage, setLocalStorage } from "../../../utils/localStore";
import { ColumnsType } from "antd/es/table";
import type { TableRowSelection } from "antd/es/table/interface";

const { Text } = Typography;

interface ScipConfig {
  userName: string;
  legalEntityUuid: string;
  legalEntityName: string;
}
interface ExportStatus {
  [id: number]: "PENDING" | "IN_PROGRESS" | "DOWNLOADED" | "FAILED";
}

const ScipNotification: React.FC<{ formData: any; fetchData: any }> = (
  props
) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [download, setDownload] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [exportStatus, setExportStatus] = useState<ExportStatus>({});
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { echaToken, scipConfigurations } = getLocalStorage("user") ?? "";
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [scipConfiguration, setScipConfiguration] = useState<any>();
  const [regenerateToken, setRegenerateToken] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleVisit = () => {
    window.open("https://ecs.echa.europa.eu/cloud/home.html", "_blank");
  };

  const onFinish = (values: any) => {
    dispatch(
      updateScip({
        ...values,
      })
    ).then(() => {
      setIsModalOpen(false);
      setDownload(false);
      setExportStatus({});
      props.fetchData();
    });
  };

  const handleSubmit = () => {
    setButtonLoading(true);
    dispatch(
      echaScipSubmision({
        selectedDossiers: props.formData,
      })
    ).then(() => {
      setButtonLoading(false);
      setIsModalOpen(false);
      props.fetchData();
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setDownload(false);
    setExportStatus({});
    props.fetchData();
  };

  const onSelectComponents = (
    newSelectedRowKeys: React.Key[],
    selectedRows: ScipConfig[]
  ) => {
    setScipConfiguration(newSelectedRowKeys);
  };

  const scipConfigurationSelection: TableRowSelection<ScipConfig> = {
    selectedRowKeys: scipConfiguration,
    onChange: onSelectComponents,
    preserveSelectedRowKeys: true,
  };

  const columns: ColumnsType<ScipConfig> = [
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
      width: 100,
    },
    {
      title: "Legal Entity Name",
      dataIndex: "legalEntityName",
      key: "legalEntityName",
      width: 100,
    },
    {
      title: "Legal Entity Uuid",
      dataIndex: "legalEntityUuid",
      key: "legalEntityUuid",
      width: 100,
    },
  ];

  const handleDownload = async () => {
    let downloadSuccess = true;
    for (const id of props.formData) {
      try {
        setExportStatus((prev) => ({
          ...prev,
          [id.DossierUuid]: "IN_PROGRESS",
        }));
        const exportResponse = await dispatch(
          readDownloadExport({ uuid: id.DossierUuid })
        );
        let downloadSucceeded = false;
        while (!downloadSucceeded) {
          const downloadStatusResponse = await dispatch(
            readDownloadStatus({
              uri: exportResponse.uri,
            })
          );

          if (downloadStatusResponse.status === "SUCCEEDED") {
            downloadSucceeded = true;
            const downloadResultResponse = await dispatch(
              readDownloadResult({
                uri: downloadStatusResponse.id,
              })
            );
            const a = document.createElement("a");
            document.body.appendChild(a);
            const blob = new Blob([downloadResultResponse], {
                type: "application/octet-stream",
              }),
              url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = id.DossierUuid + ".i6z";
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
            setExportStatus((prev) => ({
              ...prev,
              [id.DossierUuid]: "DOWNLOADED",
            }));
          } else {
            await new Promise((resolve) => setTimeout(resolve, 2000));
          }
        }
      } catch (error) {
        setExportStatus((prev) => ({ ...prev, [id.DossierUuid]: "FAILED" }));
        downloadSuccess = false;
        setCancel(true);
      }
    }
    setDownload(downloadSuccess);
  };

  const handleGenerateToken = () => {
    setLoading(true);
    dispatch(generateEchaToken())
      .then((response) => {
        const user = getLocalStorage("user");
        user.echaToken = response.echaToken;
        setLocalStorage("user", user);
      })
      .then(() => {
        setLoading(false);
        setRegenerateToken(false);
        setErrorMessage("");
      });
  };

  const listEchaEvents = () => {
    setLoading(true);
    dispatch(echaEventsCheck()).then((response) => {
      if (response === 400) {
        setErrorMessage(
          "Your ECHA Token has expired. Please regenerate the token."
        );
        setRegenerateToken(true);
        setLoading(false);
      } else if (!Array.isArray(response)) {
        setErrorMessage("The ECHA website is temporarily down for maintenance");
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    listEchaEvents();
  }, []);

  return (
    <>
      <Button type="primary" onClick={showModal} icon={<PlusOutlined />}>
        Submit to SCIP
      </Button>
      {echaToken ? (
        <Modal
          title="SCIP Submission"
          open={isModalOpen}
          closable={false}
          maskClosable={false}
          centered
          width={500}
          loading={loading}
          footer={[
            <>
              <Button key="onCancel" onClick={handleCancel}>
                Cancel
              </Button>
              {regenerateToken && (
                <Button
                  key="continue"
                  type="primary"
                  loading={loading}
                  onClick={handleGenerateToken}
                >
                  Generate Token
                </Button>
              )}
              {!errorMessage && (
                <Button
                  key="continue"
                  type="primary"
                  loading={buttonLoading}
                  onClick={handleSubmit}
                >
                  Ok
                </Button>
              )}
            </>,
          ]}
        >
          {errorMessage ? (
            <Text> {errorMessage} </Text>
          ) : (
            // : scipConfigurations?.length > 0 ? (
            //   <Table
            //     loading={loading}
            //     dataSource={scipConfigurations}
            //     columns={columns}
            //     scroll={{
            //       x: 100,
            //     }}
            //     pagination={false}
            //     rowKey="legalEntityUuid"
            //     rowSelection={{
            //       type: "radio",
            //       ...scipConfigurationSelection,
            //     }}
            //   />
            // )
            <Text>
              Folllowing product names like :{" "}
              <b>{props.formData?.map((e: any) => e.name).join(", ")}</b> will
              be submitted to ECHA
            </Text>
          )}
        </Modal>
      ) : (
        <Modal
          title=""
          open={isModalOpen}
          maskClosable={false}
          closable={false}
          centered
          width={500}
          footer={[
            download && (
              <>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button type="primary" onClick={form.submit}>
                  Save
                </Button>
              </>
            ),
            cancel && (
              <>
                <Button onClick={handleCancel}>Cancel</Button>
              </>
            ),
          ]}
        >
          <Card
            bordered={false}
            style={{ overflow: "auto", height: "30vh" }}
            styles={{
              body: {
                padding: 0,
              },
            }}
          >
            {!download && (
              <Alert
                message="Click Export to start downloading the files"
                showIcon
                type="info"
                action={
                  <Button size="small" type="primary" onClick={handleDownload}>
                    Export
                  </Button>
                }
              />
            )}
            {!download ? (
              props.formData.map((e: any, i: any) => (
                <>
                  <Card
                    key={i}
                    style={{
                      marginTop: "1rem",
                    }}
                    styles={{
                      body: {
                        padding: 5,
                      },
                    }}
                  >
                    {/* <Col key={i}> */}
                    <Space
                      align="end"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text strong>{e.name} : </Text>
                      <Space>
                        {exportStatus[e.DossierUuid] === "IN_PROGRESS" && (
                          <Spin
                            size="small"
                            percent={
                              exportStatus[e.DossierUuid] === "DOWNLOADED"
                                ? 100
                                : 0
                            }
                            indicator={<LoadingOutlined spin />}
                          />
                        )}
                        <Text type="secondary">
                          {exportStatus[e.DossierUuid] || "PENDING"}
                        </Text>
                      </Space>
                    </Space>
                    {/* </Col> */}
                  </Card>
                </>
              ))
            ) : (
              <>
                <Alert
                  message="Go to ECHA Website"
                  showIcon
                  type="info"
                  action={
                    <Button size="small" type="primary" onClick={handleVisit}>
                      ECHA
                    </Button>
                  }
                />
                <Form
                  form={form}
                  // labelCol={{ span: 6 }}
                  // wrapperCol={{ span: 16 }}
                  style={{ maxWidth: 400, marginTop: "1rem" }}
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <Row gutter={16}>
                    {props.formData.map((e: any) => (
                      <>
                        <Col key={`${e.DossierUuid}.${e.name}`} sm={24} md={24}>
                          <Form.Item label={e.name} name={[e.DossierUuid]}>
                            <Input placeholder="SCIP Number" />
                          </Form.Item>
                        </Col>
                      </>
                    ))}
                  </Row>
                </Form>
              </>
            )}
          </Card>
        </Modal>
      )}
    </>
  );
};

export default ScipNotification;
