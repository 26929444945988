import { Dispatch } from "redux";
import { setBlogsCategories } from "../slices/blogsCategoriesSlice";
import { BlogCategoriesType } from "../../utils/types/blogCategory";
import { blogsBaseUrl } from "../../utils/apiHelper";
/**
 * Fetch List of Blogs Categories
 */
export const listBlogsCategoty = (payload: any) => (dispatch: Dispatch) => {
  return new Promise<BlogCategoriesType[]>((resolve, reject) => {
    fetch(`${blogsBaseUrl}/categories`, payload)
      .then((response: Response) => response.json())
      .then((data: any) => {
        if (data && Array.isArray(data)) {
          const blogsCatgoryData = data.map((category: any) => ({
            ...category,
            order: category.order,
            id: category.id,
            title: category.title,
            slug: category.slug,
            publiushedAt: category.published_at,
          }));

          // Dispatch to Redux store with mapped data
          dispatch(
            setBlogsCategories({
              blogCategory: blogsCatgoryData,
              blogCategoryCount: blogsCatgoryData.length,
            })
          );
          resolve(blogsCatgoryData);
        } else {
          reject("Failed to fetch blogs.");
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};
