import { Dispatch } from "redux";
import { setBulletinsData } from "../slices/bulletinsSlice";
import { BulletinsDataType } from "../../utils/types/bulletins";
import { blogsBaseUrl } from "../../utils/apiHelper";

/**
 * Fetch List of Bulletin Item
 * @param {any} matchedCategoryID - The category ID to filter bulletins for blogs type.
 * @param {any} payload - Optional payload for additional request options.
 */
export const listBulletinsData =
  (matchedCategoryID: any, payload: any = {}) =>
  (dispatch: Dispatch) => {
    return new Promise<BulletinsDataType[]>((resolve, reject) => {
      fetch(
        `${blogsBaseUrl}/posts?_start=0&_sort=createdAt:desc&_where[0][categories]=${matchedCategoryID}&_where[1][categories]=646716bd52a8d90012de5907`,
        payload
      )
        .then((response: Response) => response.json())
        .then((data: any) => {
          if (data && Array.isArray(data)) {
            const bulletinData = data.map((bulletins: any) => ({
              ...bulletins,
              bannerImgUrl: bulletins.bannerImg.url,
              publishedAt: bulletins.published_at,
              slug: bulletins.slug,
            }));

            // Dispatch to Redux store with mapped data
            dispatch(
              setBulletinsData({
                bulletinsData: bulletinData,
                bulletinsDataCount: bulletinData.length,
              })
            );
            resolve(bulletinData);
          } else {
            reject("Failed to fetch blogs.");
          }
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };
