import { Dispatch } from "redux";
import { setBlogs } from "../slices/blogSlice";
import { BlogsType } from "../../utils/types/blogs";
import { blogsBaseUrl } from "../../utils/apiHelper";

/**
 * Fetch List of Blogs
 * @param {any} matchedCategoryID - The category ID to filter blogs by.
 * @param {any} payload - Optional payload for additional request options.
 */
export const listBlogs =
  (matchedCategoryID: any, payload: any = {}) =>
  (dispatch: Dispatch) => {
    return new Promise<BlogsType[]>((resolve, reject) => {
      fetch(
        `${blogsBaseUrl}/posts?_start=0&_sort=createdAt:desc&_where[0][categories]=${matchedCategoryID}`,
        payload
      )
        .then((response: Response) => response.json())
        .then((data: any) => {
          if (data && Array.isArray(data)) {
            const blogsData = data.map((blog: any) => ({
              ...blog,
              bannerImgUrl: blog.bannerImg,
              publishedAt: blog.published_at,
              slug: blog.slug,
            }));

            // Dispatch to Redux store with mapped data
            dispatch(
              setBlogs({
                blogs: blogsData,
                blogCount: blogsData.length,
              })
            );
            resolve(blogsData);
          } else {
            reject("Failed to fetch blogs.");
          }
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };
