import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { Descriptions, Tabs, Flex, Card, Avatar, Input, Space } from "antd";
import type { DescriptionsProps, TabsProps } from "antd";
import { useAppDispatch } from "../../../../redux/hooks";
import { readRegulation } from "../../../../redux/actions/regulationsAction";
import { listBlogs } from "../../../../redux/actions/blogsAction";
import { listBulletinsData } from "../../../../redux/actions/bulletinsAction";
import { getLocalStorage } from "../../../../utils/localStore";
import regulations from "../../../../utils/constants/regulations";
import { Regulation } from "../../../../utils/types/regulation";
import RegulationOverview from "./Overview";
import RegulationSubstances, { SubstanceHandle } from "./Substances";
import RegulationForm from "../../../../components/forms/RegulationForm";
import RegulationExemptions, { ExemptionHandle } from "./Exemptions";
import SubstanceForm from "../../../../components/forms/SubstanceForm";
import ExemptionForm from "../../../../components/forms/ExemptionForm";
import ErrorBoundary from "../../../../utils/errorBoundary";

const { Search } = Input;
const { Meta } = Card;

interface RegulationState {
  regulation: Regulation | null;
  tabItems: TabsProps["items"];
  descriptionItems: DescriptionsProps["items"];
  loading: boolean;
  loadingData: boolean;
  searchValue: string;
  matchedCategoryId: string | null;
}

const initialState: RegulationState = {
  regulation: null,
  tabItems: [],
  descriptionItems: [],
  loading: false,
  loadingData: true,
  searchValue: "",
  matchedCategoryId: null,
};

const RegulationDetails: React.FC = () => {
  const substanceRef = useRef<SubstanceHandle>(null);
  const exemptionRef = useRef<ExemptionHandle>(null);
  const { regulationId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState<RegulationState>(initialState);
  const permissions = getLocalStorage("role")?.permissions ?? null;

  const updateState = (updates: Partial<RegulationState>) => {
    setState((prev) => ({ ...prev, ...updates }));
  };

  const handleError = useCallback((error: Error, context: string) => {
    console.error(`Error in ${context}:`, error);
  }, []);

  const generateDescriptionItems = useCallback(
    (regulation: Regulation): DescriptionsProps["items"] => {
      return [
        {
          key: "2",
          label: "Description",
          children: regulation.description,
          span: 3,
        },
        {
          key: "1",
          label: "Type",
          children: regulation.type,
        },
      ];
    },
    []
  );

  const generateTabItems = useCallback(
    (regulation: Regulation, categoryId: string | null): TabsProps["items"] => {
      const items: TabsProps["items"] = [
        {
          key: "",
          label: "Overview",
          children: <RegulationOverview categoryId={categoryId} />,
        },
        {
          key: "substances",
          label: "Substances",
          children: (
            <RegulationSubstances
              regulationNumber={regulation.regulationNumber}
              ref={substanceRef}
            />
          ),
        },
      ];

      if (regulation.hasExemptions) {
        items.push({
          key: "exemptions",
          label: "Exemptions",
          children: (
            <RegulationExemptions
              regulationNumber={regulation.regulationNumber}
              ref={exemptionRef}
            />
          ),
        });
      }

      return items;
    },
    []
  );

  const fetchRegulationData = useCallback(
    async (id: string) => {
      updateState({ loading: true });
      try {
        const response = await dispatch(readRegulation(id));
        const formattedName = response.name.toLowerCase().replace(/\s+/g, "_");
        const matchedRegulation =
          regulations[formattedName as keyof typeof regulations];
        const categoryId = matchedRegulation?.categoryId ?? null;

        updateState({
          regulation: response,
          matchedCategoryId: categoryId,
          descriptionItems: generateDescriptionItems(response),
          tabItems: generateTabItems(response, categoryId),
          loading: false,
        });
      } catch (error) {
        handleError(error as Error, "fetch regulation data");
        updateState({ loading: false });
      }
    },
    [dispatch, generateDescriptionItems, generateTabItems, handleError]
  );

  const fetchAssociatedData = useCallback(
    async (categoryId: string) => {
      updateState({ loadingData: true });
      try {
        await Promise.all([
          dispatch(listBlogs(categoryId)),
          dispatch(listBulletinsData(categoryId)),
        ]);
      } catch (error) {
        handleError(error as Error, "fetch associated data");
      } finally {
        updateState({ loadingData: false });
      }
    },
    [dispatch, handleError]
  );

  // Effects
  useEffect(() => {
    if (regulationId) {
      fetchRegulationData(regulationId);
    }
  }, [regulationId, fetchRegulationData]);

  useEffect(() => {
    if (state.matchedCategoryId) {
      fetchAssociatedData(state.matchedCategoryId);
    }
  }, [state.matchedCategoryId, fetchAssociatedData]);

  useEffect(() => {
    const search = searchParams.get("search") || "";
    updateState({ searchValue: search });
  }, [searchParams]);

  const handleSearch = (text: string) => {
    setSearchParams({ search: text });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateState({ searchValue: e.target.value });
  };

  const handleTabChange = (key: string) => {
    navigate(key);
  };

  const currentTab = location.pathname.split("/").pop();
  const isOverviewTab = currentTab === regulationId;

  return (
    <div className="regulation-details">
      <ErrorBoundary>
        {state.regulation && (
          <Card className="mt-8">
            <Flex gap="small" justify="space-between" align="start">
              <Meta
                avatar={
                  <Avatar shape="square" size={55}>
                    {state.regulation.regulationNumber
                      .slice(0, 2)
                      .toUpperCase()}
                  </Avatar>
                }
                title={
                  <Flex gap="small" justify="space-between" align="center">
                    {state.regulation.name.toUpperCase()}
                    <RegulationForm
                      type="update"
                      fetchData={fetchRegulationData}
                      formData={state.regulation}
                    />
                  </Flex>
                }
                description={
                  <Descriptions size="small" items={state.descriptionItems} />
                }
              />
            </Flex>
          </Card>
        )}

        {state.regulation && (
          <Tabs
            activeKey={isOverviewTab ? "" : currentTab}
            items={state.tabItems}
            onChange={handleTabChange}
            tabBarExtraContent={
              !isOverviewTab && (
                <Space align="end" className="flex justify-between mb-4 mt-4">
                  <Search
                    placeholder="Search using number or name"
                    allowClear
                    onSearch={handleSearch}
                    style={{ width: 350 }}
                    value={state.searchValue}
                    onChange={handleInputChange}
                  />
                  {currentTab === "substances" &&
                    permissions?.create.includes("substances") && (
                      <SubstanceForm
                        type="create"
                        fetchData={() => substanceRef.current?.focusInput()}
                        id={state.regulation.regulationNumber}
                      />
                    )}
                  {currentTab === "exemptions" &&
                    permissions?.create.includes("exemptions") && (
                      <ExemptionForm
                        type="create"
                        fetchData={() => exemptionRef.current?.focusInput()}
                        id={state.regulation.regulationNumber}
                      />
                    )}
                </Space>
              )
            }
          />
        )}
      </ErrorBoundary>
    </div>
  );
};

export default RegulationDetails;
