export const materialsConfigurations = [
  {
    text: "Material Name",
    value: "materials.name",
    align: "start",
    width: 150,
    hidden: true,
    filter: true,
    type: "materials",
    dataType: "Textfield",
    dataValue: null,
  },
  {
    text: "FMD Document",
    value: "fmdDocument",
    hidden: true,
    filter: true,
    width: 255,
    type: "materials",
    dataValue: "Present,Not Present",
    dataType: "Dropdown",
  },
  {
    text: "Substance Name",
    value: "materials.substances.name",
    width: 255,
    hidden: true,
    filter: true,
    type: "materials",
    dataValue: null,
    dataType: "Textfield",
  },
  {
    text: "CAS Number",
    value: "materials.substances.casNumber",
    hidden: true,
    filter: true,
    width: 150,
    sortable: true,
    type: "materials",
    dataValue: null,
    dataType: "Textfield",
  },
  {
    text: "Red Flagged Regulations",
    value: "materials.substances.compliantalertRegulation",
    width: 150,
    sortable: false,
    hidden: true,
    filter: true,
    type: "materials",
    dataValue: null,
    dataType: "Textfield",
  },
];
